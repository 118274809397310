import { checkTokenValidity, token } from "../../utils/auth-token";

const URLS = {
  NOTES: "/notizen",
  NOTES_STATS: "/notizen/_stats",
  CURRENT: "/api/users/current",
  USERNAME: "/api/username",
  INFLUENCER_SECTORS: "/influencer/sectors",
  JOURNALISTS_SECTORS: "/freiejournalisten/sectors",
};

export const fetchNotes = async () => {
  const _token = token();

  if (_token === null) return;

  const url = `${process.env.API_URL}${URLS.NOTES}`;
  return await fetch(url, {
    ...(_token && { headers: { "x-auth-token": _token } }),
  });
};

export const fetchNote = async (method, id, data = undefined) => {
  const url = `${process.env.API_URL}/notizen${id ? "/" : ""}${id}`;
  return await fetch(url, {
    method: method,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "x-auth-token": token(),
    },
    ...(data && { body: JSON.stringify(data) }),
  });
};

export const fetchCurrent = async () => {
  if (checkTokenValidity() === false) return;

  const url = `${process.env.API_URL}${URLS.CURRENT}`;
  return await fetch(url, {
    headers: {
      "x-auth-token": token(),
    },
  });
};

export const fetchNotesStats = async () => {
  if (checkTokenValidity() === false) return;

  const url = `${process.env.API_URL}${URLS.NOTES_STATS}`;
  return await fetch(url, {
    headers: {
      "x-auth-token": token(),
    },
  });
};

export const fetchUserName = async (userToken) => {
  const url = `${process.env.API_URL}${URLS.USERNAME}?userId=${userToken}`;
  const response = await fetch(url);

  return await response.text();
};

export const fetchMetaInfos = async () => {
  const [current, notesStats] = await Promise.all([
    fetchCurrent(),
    fetchNotesStats(),
  ]);

  let currentData = {};
  let notesStatsData = {
    "by-bearbeitungsstatus": {},
  };

  if (current?.ok) {
    currentData = await current.json();
  }

  if (notesStats?.ok) {
    notesStatsData = await notesStats.json();
  }

  return { currentData, notesStatsData };
};

export const fetchInfluencerSectors = async () => {
  const url = `${process.env.API_URL}${URLS.INFLUENCER_SECTORS}`;
  return await fetch(url, {});
};

export const fetchJournalistsSectors = async () => {
  const url = `${process.env.API_URL}${URLS.JOURNALISTS_SECTORS}`;
  return await fetch(url, {
    headers: {},
  });
};
