import * as css from "bundle-text:./tp-result-item-content-list.scss";

import { createStyleSheet } from "../../utils/createStylesheets";

const styleSheets = createStyleSheet(css);

import TpResultItemContentListFactLink from "./tp-result-item-content-list-fact/tp-result-item-content-list-fact-link";
import TpResultItemContentListFact from "./tp-result-item-content-list-fact/tp-result-item-content-list-fact";
import TpResultItemContentListFactMedia from "./tp-result-item-content-list-fact/tp-result-item-content-list-fact-media";
const template = document.createElement("template");
template.innerHTML = `<ul>
<slot></slot>
</ul>`;

class TpResultItemContentList extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    this.shadowRoot.appendChild(template.content.cloneNode(true));
    this.shadowRoot.adoptedStyleSheets = [styleSheets];
  }

  connectedCallback() {
    const clazz = this.getAttribute("class");
    this.shadowRoot.querySelector("ul").className = clazz;
  }
}

export default TpResultItemContentList;

customElements.define("tp-result-item-content-list", TpResultItemContentList);

customElements.define(
  "tp-result-item-content-list-fact-link",
  TpResultItemContentListFactLink,
);

customElements.define(
  "tp-result-item-content-list-fact",
  TpResultItemContentListFact,
);

customElements.define(
  "tp-result-item-content-list-fact-media",
  TpResultItemContentListFactMedia,
);
