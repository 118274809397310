import * as css from "bundle-text:./tp-result-item-content-list-fact-media.scss";

import { createStyleSheet } from "../../../utils/createStylesheets";

const styleSheets = createStyleSheet(css);

const template = document.createElement("template");
template.innerHTML = `<li class="fact">
<b class="fact--label"></b>
<slot></slot/>
</li>`;

class TpResultItemContentListFactMedia extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: "open" });

    this.shadowRoot.appendChild(template.content.cloneNode(true));
    this.shadowRoot.adoptedStyleSheets = [styleSheets];
  }

  connectedCallback() {
    const label = this.getAttribute("label");
    const country = this.getAttribute("country");

    this.shadowRoot.querySelector(".fact--label").innerHTML = label;

    if (country) {
      const flag = document.createElement("tp-flags");
      flag.setAttribute("theme", "small");
      flag.setAttribute("country", country);
      const slot = this.shadowRoot.querySelector("slot");
      slot.after(flag);
    }
  }
}

export default TpResultItemContentListFactMedia;
