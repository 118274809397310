import "@material/web/button/filled-button";
import "@material/web/button/text-button";
import * as css from "bundle-text:./tp-button.scss";
import { createStyleSheet } from "../../utils/createStylesheets";

const styleSheets = createStyleSheet(css);

const templateFilledButton = document.createElement("template");
templateFilledButton.innerHTML = `<md-filled-button class="tp-button"><slot/></md-filled-button>`;

const templateTextButton = document.createElement("template");
templateTextButton.innerHTML = `<md-text-button class="tp-button"><slot/></md-text-button>`;

class TpButton extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: "open" });

    this.shadowRoot.adoptedStyleSheets = [styleSheets];
  }

  connectedCallback() {
    if (
      [undefined, null, "primary", "secondary", "tertiary"].includes(
        this.getAttribute("appearance"),
      )
    ) {
      this.shadowRoot.appendChild(templateFilledButton.content.cloneNode(true));
    }

    if (this.getAttribute("appearance")?.startsWith("text")) {
      this.shadowRoot.appendChild(templateTextButton.content.cloneNode(true));
    }
  }
}

export default TpButton;

customElements.define("tp-button", TpButton);
