import {
  Component,
  For,
  createEffect,
  createResource,
  createSignal,
  onMount,
} from "solid-js";

import SavedSearchesRow from "./saved-search-row/saved-searches-row";

import {
  deleteSearch,
  editSearch,
  fetchSavedSearches,
} from "../../services/api/search-api-handler";

import { mapItemsToSearchLocation } from "../../services/data-mapper";
import { token } from "../../utils/auth-token";
import { applicationStore } from "../../store";

import "./saved-searches.scss";

async function fetchAllSearches() {
  const { setApplicationState } = applicationStore;
  const response = await fetchSavedSearches();
  if (response === null || response === undefined) return;
  const body = await response.json();
  setApplicationState("searches", body?.length);
  return body;
}

const SavedSearches: Component = () => {
  const [deleteId, setDeleteId] = createSignal("");
  const [data, { refetch }] = createResource(fetchAllSearches);

  let dialog;
  onMount(() => {
    dialog = document.getElementById("delete-saved-search-dialog");
  });

  createEffect(() => {
    if (token() !== null) {
      refetch();
    }
  });

  const closeDialog = () => {
    dialog.dispatchEvent(new Event("delete-saved-search:dialog:close"));
  };

  const handleDeleteOverlay = async (id, name) => {
    setDeleteId(id);
    dialog.querySelector("p").innerText =
      `Wollen Sie die gespeicherte Suche "${name}" unwiderruflich löschen?`;
    dialog.dispatchEvent(new Event("delete-saved-search:dialog:open"));
  };

  const handleDelete = async () => {
    const response = await deleteSearch(deleteId());
    if (response.ok) {
      refetch();
      setDeleteId("");
      closeDialog();
    }
  };

  const handleOpenSearch = async (search) => {
    const redirectUrl = mapItemsToSearchLocation(search);
    location.replace(redirectUrl);
  };

  const handleEdit = async (search) => {
    const response = await editSearch(search);
    if (response.ok) {
      refetch();
    }
  };

  return (
    <>
      <section class="section-append" />
      <section class="content">
        <p>Wählen Sie hier bitte eine gespeicherte Suchabfrage:</p>
        <br />

        {data.loading ? (
          <tp-circular-progress />
        ) : (
          <For each={data()}>
            {(search) => (
              <SavedSearchesRow
                search={search}
                show-buttons={false}
                handleDelete={handleDeleteOverlay}
                handleSave={handleEdit}
                handleOpenSearch={handleOpenSearch}
              />
            )}
          </For>
        )}

        <tp-dialog
          dialog-id="delete-saved-search"
          id="delete-saved-search-dialog"
        >
          <div slot="title">Gespeicherte Suche löschen</div>

          <p />

          <div slot="action_buttons">
            <tp-button appearance="text-dark" onClick={closeDialog}>
              ZURÜCK
            </tp-button>

            <tp-button id="deleteButton" onClick={handleDelete}>
              LÖSCHEN
            </tp-button>
          </div>
        </tp-dialog>
      </section>
    </>
  );
};

export default SavedSearches;
