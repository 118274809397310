import * as css from "bundle-text:./tp-result-item-content-list-fact.scss";

import { createStyleSheet } from "../../../utils/createStylesheets";

const styleSheets = createStyleSheet(css);

const template = document.createElement("template");
template.innerHTML = `<li class="fact">
<b class="fact--label"></b>
<span></span>
</li>`;

class TpResultItemContentListFact extends HTMLElement {
  static get observedAttributes() {
    return ["label"];
  }

  constructor() {
    super();
    this.attachShadow({ mode: "open" });

    this.shadowRoot.appendChild(template.content.cloneNode(true));
    this.shadowRoot.adoptedStyleSheets = [styleSheets];
  }

  setLabel() {
    const label = this.getAttribute("label");
    this.shadowRoot.querySelector("b").innerHTML = label;
  }

  connectedCallback() {
    this.setLabel();
    const value = this.getAttribute("value");
    this.shadowRoot.querySelector("span").innerHTML = value;
  }

  attributeChangedCallback() {
    this.setLabel();
  }
}

export default TpResultItemContentListFact;
