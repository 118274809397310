import { Component, createEffect, lazy } from "solid-js";
const NewSearch = lazy(() => import("../saved-searches/new-search.route"));

import Notes from "../notes/notes.route";
import SavedSearches from "../saved-searches/saved-searches.route";
import NewPressetext from "../pressetexte/new-pressetext.route";
import Pressetexte from "../pressetexte/pressetexte.route";
import { PressetexteProvider } from "../../store/pressetexte-store";

import { applicationStore } from "../../store";
import { COLLAPSIBLE_CONTENT } from "../../constants";
import Help from "../help/help.route";

const CollapsibleContent: Component = () => {
  let notesContainer,
    searchContainer,
    newSearchContainer,
    pressetexteContainer,
    newPressetextContainer,
    helpContainer;

  const { applicationState } = applicationStore;

  createEffect(() => {
    notesContainer.dispatchEvent(new Event("container:close"));
    searchContainer.dispatchEvent(new Event("container:close"));
    newSearchContainer.dispatchEvent(new Event("container:close"));
    pressetexteContainer.dispatchEvent(new Event("container:close"));
    newPressetextContainer.dispatchEvent(new Event("container:close"));
    helpContainer.dispatchEvent(new Event("container:close"));

    if (applicationState.showCollapsibleContent === true) {
      switch (applicationState.collapsibleContent) {
        case COLLAPSIBLE_CONTENT.NOTES:
          notesContainer.dispatchEvent(new Event("container:open"));
          break;
        case COLLAPSIBLE_CONTENT.SEARCHES:
          searchContainer.dispatchEvent(new Event("container:open"));
          break;
        case COLLAPSIBLE_CONTENT.NEW_SEARCH:
          newSearchContainer.dispatchEvent(new Event("container:open"));
          break;
        case COLLAPSIBLE_CONTENT.NEW_PRESSETEXT:
          newPressetextContainer.dispatchEvent(new Event("container:open"));
          setTimeout(() => {
            // https://stackoverflow.com/a/71181885/372019
            newPressetextContainer.scrollIntoView(true);
          }, 10);
          break;
        case COLLAPSIBLE_CONTENT.PRESSETEXTE:
          pressetexteContainer.dispatchEvent(new Event("container:open"));
          break;
        case COLLAPSIBLE_CONTENT.HELP:
          helpContainer.dispatchEvent(new Event("container:open"));
          break;
        default:
          break;
      }
    }
  });

  return (
    <div style={{ "background-color": "#3a5d8c" }}>
      <tp-toggle-container ref={notesContainer}>
        <div style={{ "padding-bottom": "20px" }}>
          <Notes />
        </div>
      </tp-toggle-container>
      <tp-toggle-container ref={newSearchContainer}>
        <NewSearch />
      </tp-toggle-container>
      <tp-toggle-container ref={searchContainer}>
        <SavedSearches />
      </tp-toggle-container>
      <PressetexteProvider>
        <tp-toggle-container ref={pressetexteContainer}>
          <Pressetexte />
        </tp-toggle-container>
        <tp-toggle-container ref={newPressetextContainer}>
          <NewPressetext />
        </tp-toggle-container>
      </PressetexteProvider>
      <tp-toggle-container ref={helpContainer}>
        <Help />
      </tp-toggle-container>
    </div>
  );
};

export default CollapsibleContent;
