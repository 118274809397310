import "@material/web/radio/radio";
import * as css from "bundle-text:./tp-radio.scss";

import { createStyleSheet } from "../../utils/createStylesheets";

const styleSheets = createStyleSheet(css);

const template = document.createElement("template");
template.innerHTML = `<div class="tp-radio"><md-radio></md-radio><label><slot/></div>`;

class TpRadio extends HTMLElement {
  static get observedAttributes() {
    return ["checked"];
  }

  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    this.shadowRoot.appendChild(template.content.cloneNode(true));
    this.shadowRoot.adoptedStyleSheets = [styleSheets];
  }

  handleChange(event) {
    const selectedRadio = event.target;

    this.dispatchEvent(
      new CustomEvent("change", {
        bubbles: true,
        composed: true,
        detail: {
          value: selectedRadio.getAttribute("value"),
        },
      }),
    );
  }

  connectedCallback() {
    this.$element = this.shadowRoot.querySelector("md-radio");
    for (const attribute of this.attributes) {
      if (attribute.name === "id") {
        this.shadowRoot
          .querySelector("label")
          .setAttribute("for", attribute.value);
      }

      this.$element.setAttribute(attribute.name, attribute.value);
    }
    this.addEventListener("click", this.handleChange);
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (oldValue === newValue) return;
    if (name === "checked") {
      if (this.$element?.hasAttribute("checked")) {
        this.$element?.removeAttribute("checked");
      } else {
        this.$element?.setAttribute("checked", "");
      }
    }
  }
}

export default TpRadio;

customElements.define("tp-radio", TpRadio);
