import * as css from "bundle-text:./tp-result-item-content-list-fact-link.scss";
import { createStyleSheet } from "../../../utils/createStylesheets";

const styleSheets = createStyleSheet(css);

const template = document.createElement("template");
template.innerHTML = `<li class="fact-link"></li>`;

class TpResultItemContentListFactLink extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: "open" });

    this.shadowRoot.appendChild(template.content.cloneNode(true));
    this.shadowRoot.adoptedStyleSheets = [styleSheets];
  }

  connectedCallback() {
    const url = this.getAttribute("url");
    const label = this.getAttribute("label");

    const li = this.shadowRoot.querySelector("li");
    const aTag = document.createElement("a");
    aTag.innerHTML = label;
    aTag.src = url;

    li.appendChild(aTag);
  }
}

export default TpResultItemContentListFactLink;
