import { createRoot } from "solid-js";
import { createStore } from "solid-js/store";

import { SELECTION_MODES, SOCIAL_TYPES } from "../constants";
import { getTomorrowsDate } from "../utils/date-utils";
import { influencerCategories } from "../components/influencer/influencer.route";

const commonFields = {
  searchTerm: null,
  sort: "",
  offset: 0,
  selectionMode: SELECTION_MODES.SELECTED,
  selectionExclusions: [],
  isLoading: false,
  hits: [],
  totalHits: null,
  isSelectAllButtonChecked: true,
};

const topicSearchFields = {
  mode: null,
  excludedTerms: "",
  countries: [],
  mediaGenre: [],
  publishDateEnd: "",
  publishDateStart: getTomorrowsDate(),
  zip: "",
};

const fairsFields = {
  mode: null,
  excludedTerms: "",
  countries: [],
  mediaGenre: [],
  from: getTomorrowsDate(),
  to: "",
  zip: "",
};

const influencerFields = {
  category: influencerCategories.INFLUENCER,
  zip: "",
  countries: [],
  genre: [],
};

export type SelectedTopicType = {
  id?: string;
  thema?: string;
  medientitel?: string;
  gattung?: string;
  erscheinungstermin?: string;
  ansprechpartner?: {
    vorname?: string;
    nachname?: string;
    telefon?: string;
    mail?: string;
  };
};

const pressetextTopicFields: { selectedTopic?: SelectedTopicType } = {
  selectedTopic: {},
};

export type PressetextType = {
  id: string;
  pressetextId: string;
  themaId: string;
  thema: string;
  medientitel?: string;
  gattung?: string;
  erscheinungstermin?: string;
  ansprechpartner?: {
    vorname?: string;
    nachname?: string;
    funktion?: string;
    telefon?: string;
    mail?: string;
    email?: string;
  };
  companyName?: string;
  companyProfile?: string;
  companyContact?: string;
  productName?: string;
  productDetails?: string;
  characterCount?: number;

  subject?: string;
  body?: string;
  social?: string;

  title?: string;
  urheber?: string;
  updateTime?: string;
};

export const applicationStore = createRoot(() => {
  const [applicationState, setApplicationState] = createStore({
    isLoggedIn: false,
    whitelabel: false,
    notesInProgress: 0,
    roles: ["default"],
    searches: 0,
    pressetexteCount: 0,
    visibleModules: [],
    collapsibleContent: null,
    showCollapsibleContent: false,
  });
  return { applicationState, setApplicationState };
});

export const topicsStore = createRoot(() => {
  const [topicsState, setTopicsState] = createStore({
    ...commonFields,
    ...topicSearchFields,
  });
  return { topicsState, setTopicsState };
});

export const fairsStore = createRoot(() => {
  const [fairsState, setFairsState] = createStore({
    ...commonFields,
    ...fairsFields,
  });
  return { fairsState, setFairsState };
});

export const socialMediaStore = createRoot(() => {
  const [socialMediaState, setSocialMediaState] = createStore({
    ...commonFields,
    socialMediaType: SOCIAL_TYPES.BLOGS,
  });
  return { socialMediaState, setSocialMediaState };
});

export const influencerStore = createRoot(() => {
  const [influencerState, setInfluencerState] = createStore({
    ...commonFields,
    ...influencerFields,
  });
  return { influencerState, setInfluencerState };
});

export const pressetextStore = createRoot(() => {
  const [pressetextState, setPressetextState] = createStore({
    ...pressetextTopicFields,
  });
  return { pressetextState, setPressetextState };
});

export const pressetextCompletionStore = createRoot(() => {
  const [pressetextCompletionState, setPressetextCompletionState] = createStore(
    {
      isLoading: false,
      showContent: false,
      isLoadingSocial: false,
      showSocialContent: false,
    },
  );
  return { pressetextCompletionState, setPressetextCompletionState };
});

export const STORE_TYPES = {
  TOPICS: 0,
  FAIRS: 1,
  SOCIAL: 2,
  INFLUENCER: 3,
  PRESSETEXTE: 4,
};

export const resetStores = (type) => {
  switch (type) {
    case STORE_TYPES.TOPICS:
      topicsStore.setTopicsState({
        ...commonFields,
        ...topicSearchFields,
      });
      break;

    case STORE_TYPES.FAIRS:
      fairsStore.setFairsState({
        ...commonFields,
        ...fairsFields,
      });
      break;

    case STORE_TYPES.SOCIAL:
      socialMediaStore.setSocialMediaState({
        ...commonFields,
        socialMediaType: SOCIAL_TYPES.BLOGS,
      });
      break;

    case STORE_TYPES.INFLUENCER:
      influencerStore.setInfluencerState({
        ...commonFields,
        ...influencerFields,
      });
      break;

    case STORE_TYPES.PRESSETEXTE:
      pressetextStore.setPressetextState({
        ...pressetextTopicFields,
      });
      break;

    default:
      break;
  }
};
