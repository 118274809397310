import { token } from "../../utils/auth-token";
import { PressetextType } from "../../store";

export const fetchData = async (searchTerm) => {
  if (process.env.API_URL === undefined) throw Error("URL nicht gefunden");
  const url = `${process.env.API_URL}${searchTerm}`;

  if (!searchTerm) return null;

  return (
    await fetch(url, {
      ...(token() && { headers: { "x-auth-token": token() } }),
    })
  ).json();
};

export const fetchArticle = async (searchTerm, mediaId) => {
  const url = `${process.env.API_URL}/medien/artikel?includeTerms=${searchTerm}*&medienId=${mediaId}`;

  return (
    await fetch(url, {
      ...(token() && { headers: { "x-auth-token": token() } }),
    })
  ).json();
};

export const fetchSuggestion = async (searchTerm, type, signal = null) => {
  const url = `${process.env.API_URL}/${type}/suggestions?limit=10&prefix=${searchTerm}`;
  return await fetch(url, { signal });
};

export const fetchExportDocument = async (
  mediatype,
  fileExtension,
  searchTerm,
  selectionMode,
) => {
  const types = ["csv", "xlsx"];
  const mimeTypes = ["text/plain;charset=utf-8", "application/zip"];

  // remove offset, because api will fail; only on xlsx;
  // TODO fix the backend impl
  searchTerm = searchTerm.substr(0, searchTerm.indexOf("offset") - 1);

  const url = `${process.env.API_URL}/${mediatype}.${types[fileExtension]}?${searchTerm}&selectionMode=${selectionMode}`;

  return await fetch(url, {
    headers: {
      // TODO content-type required?
      "Content-Type": mimeTypes[fileExtension],
      "x-auth-token": token(),
    },
  });
};

export const fetchSavedSearches = async () => {
  const url = `${process.env.API_URL}/suchen`;
  if (!token()) return;
  return await fetch(url, {
    ...(token() && { headers: { "x-auth-token": token() } }),
  });
};

export const saveSearch = async (data) => {
  const url = `${process.env.API_URL}/suchen`;

  return await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "x-auth-token": token(),
    },
    body: JSON.stringify(data),
  });
};

export const deleteSearch = async (id: string) => {
  const url = `${process.env.API_URL}/suchen/${id}`;

  return await fetch(url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "x-auth-token": token(),
    },
  });
};

export const editSearch = async (data) => {
  const url = `${process.env.API_URL}/suchen/${data.id}`;

  return await fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "x-auth-token": token(),
    },
    body: JSON.stringify(data),
  });
};

export const draftPressetext = async (data: PressetextType) => {
  const url = `${process.env.API_URL}/pressetext/draft`;

  return await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "x-auth-token": token(),
    },
    body: JSON.stringify(data),
  });
};

export const draftPressetextForSocialMedia = async (pressetextId, data) => {
  const url = `${process.env.API_URL}/pressetext/${pressetextId}/social`;

  return await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "x-auth-token": token(),
    },
    body: JSON.stringify(data),
  });
};

export const fetchSavedPressetexte = async () => {
  const url = `${process.env.API_URL}/pressetexte`;
  if (!token()) return;
  return await fetch(url, {
    ...(token() && { headers: { "x-auth-token": token() } }),
  });
};

export const savePressetext = async (data) => {
  const url = `${process.env.API_URL}/pressetexte`;

  return await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "x-auth-token": token(),
    },
    body: JSON.stringify(data),
  });
};

export const updatePressetext = async (data) => {
  const url = `${process.env.API_URL}/pressetexte/${data.pressetextId}`;

  return await fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "x-auth-token": token(),
    },
    body: JSON.stringify(data),
  });
};

export const deletePressetext = async (id: string) => {
  const url = `${process.env.API_URL}/pressetexte/${id}`;
  return await fetch(url, {
    method: "DELETE",
    headers: {
      "x-auth-token": token(),
    },
  });
};
