import { Component, createEffect, onMount } from "solid-js";

import { influencerStore } from "../../../store";

const ZipAndCountries: Component = (props) => {
  const { influencerState, setInfluencerState } = influencerStore;

  const setCountries = () => {
    const countries = document.querySelectorAll("#countries li");
    Array.from(countries).forEach((country) => {
      if (influencerState.countries.includes(country.getAttribute("value"))) {
        country.setAttribute("selected", "");
      }
    });
  };

  onMount(async () => {
    createEffect(() => {
      setCountries();
    });
  });

  const handleKeyButtons = (event) => {
    if (event.key === "Enter") {
      props.handleSearch();
    }
  };

  return (
    <div class="mdc-layout-grid__inner">
      <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6">
        <tp-text-field
          id="zip-code"
          label="Postleizahl oder Ort"
          attr:value={influencerState.zip}
          onKeyDown={(e) => {
            setInfluencerState("zip", e.target.value);
            handleKeyButtons(e);
          }}
          onInput={(e) => setInfluencerState("zip", e.target.value)}
        />
      </div>
      <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6">
        <tp-multi-select
          placeholder="Land"
          id="countries"
          onChange={(e) => {
            setInfluencerState("countries", e.detail);
          }}
        >
          <li value="DE" short="DE">
            Deutschland
          </li>
          <li value="AT" short="AT">
            Österreich
          </li>
          <li value="CH" short="CH">
            Schweiz
          </li>
        </tp-multi-select>
      </div>
    </div>
  );
};

export default ZipAndCountries;
