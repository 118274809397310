export const MODES = {
  topics: "themen",
  media: "medientitel",
  all: "alle",
  group: "themengruppe",
  congress: "messen",
  memorials: "thementage",
};

export const MODES_GERMAN = {
  themen: "themes",
  medientitel: "media",
  alle: "all",
  themengruppe: "group",
};

export const MODES_API = {
  themen: "BY_THEMA",
  medientitel: "BY_MEDIENTITEL",
  alle: "EXTENDED",
  themengruppe: "BY_THEMENGRUPPE",
};

export const SELECTION_MODES = {
  SELECTED: "SELECTED",
  DESELECTED: "DESELECTED",
};

export const CATEGORIES = {
  TOPICS: "themen",
  MEDIA: "medien",
  GROUPS: "themengruppen",
  DAYS: "thementage",
  FAIRS: "messen",
};

export const COUNTRYCODE_TO_GERMAN = {
  DE: "Deutschland",
  AT: "Österreich",
  CH: "Schweiz",
};

export const COUNTRYCODE_FROM_GERMAN = {
  Deutschland: "DE",
  Österreich: "AT",
  Schweiz: "CH",
  DE: "DE",
  AT: "AT",
  CH: "CH",
};

export const EXPORT_OPTIONS = ["csv", "xlsx"];

export const SOCIAL_TYPES = {
  BLOGS: "blogs",
  TWITTER: "twitter",
  FACEBOOK: "facebook",
  INSTAGRAM: "instagram",
  TIKTOK: "tiktok",
  PINTEREST: "pinterest",
  REDDIT: "reddit",
  FLICKR: "flickr",
  LINKEDIN: "linkedin",
  XING: "xing",
  YOUTUBE: "youtube",
  VIMEO: "vimeo",
};

export const NOTES_STATUS = {
  IN_BEARBEITUNG: "In Bearbeitung",
  ERLEDIGT: "Erledigt",
};

export const COLLAPSIBLE_CONTENT = {
  NOTES: 0,
  NEW_SEARCH: 1,
  SEARCHES: 2,
  HELP: 3,
  NEW_PRESSETEXT: 4,
  PRESSETEXTE: 5,
};
