import * as cssText from "bundle-text:./tp-toggle-container.scss";
import { createStyleSheet } from "../../utils/createStylesheets.js";

const styleSheets = createStyleSheet(cssText);

const template = document.createElement("template");
template.innerHTML = `<div class="container"><div class="content"><slot/></div></div>`;

class TpToggleContainer extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    this.shadowRoot.appendChild(template.content.cloneNode(true));

    this.shadowRoot.adoptedStyleSheets = [styleSheets];

    this.isContainerOpen = false;
  }

  openContainer() {
    this.container.style.maxHeight = this.container.scrollHeight - 20 + "px";
    this.isContainerOpen = true;
  }

  closeContainer() {
    this.container.style.maxHeight = null;
    this.isContainerOpen = false;
  }

  connectedCallback() {
    this.container = this.shadowRoot.querySelector(".container");
    this.addEventListener("container:open", this.openContainer);
    this.addEventListener("container:close", this.closeContainer);

    const heightObserver = this.initHeightObserver();
    heightObserver.observe(this.shadowRoot.querySelector(".content"));
  }

  disconnectedCallback() {
    this.removeEventListener("container:open", this.openContainer);
    this.removeEventListener("container:close", this.closeContainer);
  }

  initHeightObserver() {
    // ResizeObserver Initialisierung
    return new ResizeObserver((entries) => {
      for (let entry of entries) {
        const newHeight = entry.target.clientHeight;

        if (
          this.container.style.maxHeight !== null &&
          this.isContainerOpen === true
        ) {
          this.container.style.maxHeight = `${newHeight}px`;
        }
      }
    });
  }
}

export default TpToggleContainer;

customElements.define("tp-toggle-container", TpToggleContainer);
