/** All SearchTerms need * at the end, except terms in singleqoutes
 *
 * "regeltechnik steuerungstechnik" messtechnik emv to
 * "regeltechnik steuerungstechnik" messtechnik* emv*
 *
 * @param s
 *
 *
 * @returns well transformed searchterm-String
 */

export const transformSearchInput = (_searchTerm: string) => {
  _searchTerm = _searchTerm.replaceAll(".", "").replaceAll(",", "").trim();

  let searchTerm = "";
  const splitted = _searchTerm.split(" ");

  let isInQuote = false;
  splitted.forEach((word) => {
    if (word.startsWith('"')) {
      isInQuote = true;
    }

    if (isInQuote === false) {
      searchTerm += word + "* ";
    } else {
      searchTerm += word + " ";
    }

    if (word.endsWith('"')) {
      isInQuote = false;
    }
  });

  return searchTerm.trim();
};
