import { For, Show } from "solid-js";

const InfluencerList = (props) => {
  const setCollapsibleContent = (index, title, content) => {
    const collapsible = document.getElementById(`collapsible-${index}`);
    collapsible.setAttribute("title", title);
    collapsible.innerHTML = content;
    collapsible.dispatchEvent(new Event("collapsible:show"));
  };

  const openCollapsible = (e, index, content) => {
    e.preventDefault();
    setCollapsibleContent(index, "Profil", content);
  };

  return (
    <>
      <Show when={props.totalHits === 0}>
        <h2>
          Ihre Suchabfrage liefert keine Treffer. Bitte ändern/ergänzen Sie Ihre
          Suchkriterien.
        </h2>
      </Show>
      <For each={props.hits}>
        {(influencer, index) => (
          <div style={{ padding: "0 25px 0 25px" }}>
            <tp-result-item-with-image
              attr:image={influencer.foto}
              attr:heading={
                influencer.name ? influencer.name : influencer.name_online
              }
            >
              <div style={{ flex: "1 1 80%", "margin-right": "65px" }}>
                <div>
                  {influencer.kurzprofil}
                  <Show when={influencer.kurzprofil}>
                    ...(
                    <a
                      href=""
                      onClick={(e) =>
                        openCollapsible(e, index(), influencer.profil)
                      }
                    >
                      vollständiges Kurzprofil)
                    </a>
                  </Show>
                </div>
                <br />
                <div>
                  <strong>Themenbereiche: </strong>
                  {influencer.branchen
                    ? influencer.branchen
                    : influencer.themenbereich}
                </div>
                <br />
                <ul class="unstyled">
                  <Show when={influencer.website}>
                    <li>
                      <a href={influencer.website} target="_blank">
                        {influencer.website}
                      </a>
                    </li>
                  </Show>
                  <Show when={influencer.link1}>
                    <li>
                      <a href={influencer.link1} target="_blank">
                        {influencer.link1}
                      </a>
                    </li>
                  </Show>
                  <Show when={influencer.link2}>
                    <li>
                      <a href={influencer.link2} target="_blank">
                        {influencer.link2}
                      </a>
                    </li>
                  </Show>
                  <Show when={influencer.link3}>
                    <li>
                      <a href={influencer.link3} target="_blank">
                        {influencer.link3}
                      </a>
                    </li>
                  </Show>
                  <Show when={influencer.link4}>
                    <li>
                      <a href={influencer.link4} target="_blank">
                        {influencer.link4}
                      </a>
                    </li>
                  </Show>
                  <Show when={influencer.link5}>
                    <li>
                      <a href={influencer.link5} target="_blank">
                        {influencer.link5}
                      </a>
                    </li>
                  </Show>
                  <Show when={influencer.link6}>
                    <li>
                      <a href={influencer.link6} target="_blank">
                        {influencer.link6}
                      </a>
                    </li>
                  </Show>
                  <Show when={influencer.link7}>
                    <li>
                      <a href={influencer.link7} target="_blank">
                        {influencer.link7}
                      </a>
                    </li>
                  </Show>
                </ul>
              </div>
              <tp-result-item-content-list style={{ flex: "1 1 45%" }}>
                <Show when={influencer.username}>
                  <tp-result-item-content-list-fact
                    label="Username:"
                    attr:value={influencer.username}
                  />
                </Show>

                <Show when={influencer.follower}>
                  <tp-result-item-content-list-fact
                    label="Follower:"
                    attr:value={influencer.follower}
                  />
                </Show>

                <Show when={influencer.format}>
                  <tp-result-item-content-list-fact
                    label="Formate:"
                    attr:value={influencer.format}
                  />
                </Show>

                <br />

                <Show when={influencer.telefon_fest}>
                  <tp-result-item-content-list-fact
                    label="Telefon:"
                    attr:value={influencer.telefon_fest}
                  />
                </Show>

                <Show when={influencer.telefon_mobil}>
                  <tp-result-item-content-list-fact
                    label="Telefon:"
                    attr:value={influencer.telefon_mobil}
                  />
                </Show>

                <Show when={influencer.skype}>
                  <tp-result-item-content-list-fact
                    label="Skype:"
                    attr:value={influencer.skype}
                  />
                </Show>

                <Show when={influencer.email}>
                  <tp-result-item-content-list-fact
                    label="E-Mail:"
                    attr:value={influencer.email}
                  />
                </Show>

                <Show when={influencer.ort}>
                  <tp-result-item-content-list-fact
                    label="Ort:"
                    attr:value={influencer.ort}
                  />
                </Show>
              </tp-result-item-content-list>
              <Show when={influencer.kurzprofil}>
                <div slot="info-box" style={{ "margin-left": "115px" }}>
                  <tp-collapsible-box attr:id={`collapsible-${index()}`} />
                </div>
              </Show>
            </tp-result-item-with-image>
          </div>
        )}
      </For>
    </>
  );
};

export default InfluencerList;
