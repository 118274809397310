import { createSignal } from "solid-js";

export const [token, _setToken] = createSignal(
  sessionStorage.getItem("ls.auth_token"),
);

export const setToken = (token: string) => {
  _setToken(token);
  sessionStorage.setItem("ls.auth_token", token);
};
export const clearToken = () => {
  sessionStorage.removeItem("ls.auth_token");
  _setToken(null);
};

export const checkTokenValidity = () => {
  if (token() === null || token() === undefined || token() === "") {
    return false;
  }

  return true;
};
