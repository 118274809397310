import { Component, For, createEffect, createSignal, onMount } from "solid-js";

import { influencerStore } from "../../../store";
import { influencerCategories } from "../influencer.route";
import {
  fetchInfluencerSectors,
  fetchJournalistsSectors,
} from "../../../services/api/meta-data-api-handler";

const IndustryAndTypes: Component = () => {
  const { influencerState, setInfluencerState } = influencerStore;

  const [listOptions, setListOptions] = createSignal({
    influencer: [],
    journalists: [],
  });

  const updateListItems = async () => {
    const [influcencerListResponse, journalistsListResponse] =
      await Promise.all([fetchInfluencerSectors(), fetchJournalistsSectors()]);
    if (influcencerListResponse.ok && journalistsListResponse.ok) {
      const [influcencerList, journalistsList] = await Promise.all([
        influcencerListResponse.json(),
        journalistsListResponse.json(),
      ]);
      if (influcencerList && journalistsList) {
        setListOptions({
          influencer: influcencerList,
          journalists: journalistsList,
        });
      }
    }
  };

  onMount(() => {
    updateListItems();
  });

  // needs seperate createffect to avoid infinite loop.
  createEffect(() => {
    if (
      listOptions().influencer?.length > 0 ||
      listOptions().journalists?.length > 0
    ) {
      const genres = document.querySelectorAll("#media-genre li");

      Array.from(genres).forEach((genre) => {
        if (influencerState.genre.includes(genre.getAttribute("value"))) {
          genre.setAttribute("selected", "");
        }
      });
    }
  });

  const setCategory = (category) => ({
    ...(influencerState.category === category ? { "attr:checked": true } : {}),
  });

  return (
    <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6">
      <tp-multi-select
        placeholder="Branche"
        id="media-genre"
        onChange={(e) => setInfluencerState("genre", e.detail)}
        // attr:selected-items={influencerState.genre.toLocaleString()}
      >
        <For
          each={
            influencerState.category === influencerCategories.INFLUENCER
              ? listOptions().influencer
              : listOptions().journalists
          }
        >
          {(option) => <li attr:value={option.name}>{option.name}</li>}
        </For>
      </tp-multi-select>
      <div class="radio-row">
        <tp-radio-group
          onChange={(e) => {
            setInfluencerState("genre", []);
            setInfluencerState("category", e.target.getAttribute("value"));
          }}
        >
          <tp-radio
            radio-id="influencer"
            attr:value={influencerCategories.INFLUENCER}
            {...setCategory(influencerCategories.INFLUENCER)}
          >
            Suche nach Social-Influencer:innen
          </tp-radio>
          <tp-radio
            radio-id="journalist"
            attr:value={influencerCategories.FREIE_JOURNALISTEN}
            {...setCategory(influencerCategories.FREIE_JOURNALISTEN)}
          >
            Suche nach Freien Journalisten
          </tp-radio>
        </tp-radio-group>
      </div>
    </div>
  );
};

export default IndustryAndTypes;
