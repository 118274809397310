import { applicationStore } from "../../../../store/";

const { applicationState, setApplicationState } = applicationStore;

export const showToggledContent = (content) => {
  if (
    applicationState.showCollapsibleContent === true &&
    applicationState.collapsibleContent === content
  ) {
    setApplicationState("showCollapsibleContent", false);
  } else {
    setApplicationState("collapsibleContent", content);
    setApplicationState("showCollapsibleContent", true);
  }
};
