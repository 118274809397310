import * as cssText from "bundle-text:./tp-nav-item.scss";
import { createStyleSheet } from "../../../utils/createStylesheets";

const styleSheets = createStyleSheet(cssText);

const template = document.createElement("template");
template.innerHTML = `<li class="nav--item"><slot></slot></li>`;

class TPNavItem extends HTMLElement {
  static get observedAttributes() {
    return ["active", "counter"];
  }

  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    this.shadowRoot.appendChild(template.content.cloneNode(true));
    this.shadowRoot.adoptedStyleSheets = [styleSheets];

    this.toggleClass();
  }

  connectedCallback() {
    const icon = this.getAttribute("icon");
    if (icon === "true") {
      this.shadowRoot.querySelector(".nav--item").classList.add("icon");
    }

    const muted = this.getAttribute("muted");
    if (muted === "true") {
      this.shadowRoot.querySelector(".nav--item").classList.add("muted");
    }

    this.handleCountAttribute();
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (oldValue === newValue) return;

    switch (name) {
      case "active":
        this.toggleClass();
        break;

      case "counter":
        this.handleCountAttribute();
        break;
    }
  }

  handleCountAttribute() {
    const counter = this.getAttribute("counter");
    if (!counter) return;

    const liNode = this.shadowRoot.querySelector("li");
    const counterNode = liNode.querySelector(".counter");
    if (counterNode) {
      counterNode.innerHTML = counter;
    } else {
      const newCounterNode = document.createElement("div");
      newCounterNode.classList.add("counter");
      newCounterNode.innerHTML = counter;
      liNode.append(newCounterNode);
    }
  }

  toggleClass() {
    const active = this.getAttribute("active");

    if (active === "true") {
      this.shadowRoot.querySelector("li").classList.add("active");
    } else {
      this.shadowRoot.querySelector("li").classList.remove("active");
    }
  }
}

export default TPNavItem;

customElements.define("tp-nav-item", TPNavItem);
