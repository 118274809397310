import * as css from "bundle-text:./tp-result-item.scss";
import { createStyleSheet } from "../../utils/createStylesheets";

const styleSheets = createStyleSheet(css);

const template = document.createElement("template");
template.innerHTML = `<div class="search-items">
  <div class="heading"><slot name="heading" /></div>
  <div class="result-container">
    <slot></slot>
  </div>
  <slot name="info-box"></slot>
</div>
<div class="hr"></div/>`;

class TpResultItems extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    this.shadowRoot.appendChild(template.content.cloneNode(true));
    this.shadowRoot.adoptedStyleSheets = [styleSheets];
  }
}

export default TpResultItems;

customElements.define("tp-result-item", TpResultItems);
