const downloadURI = (uri: string, name: string, newWindow = false): void => {
  const link = document.createElement("a");
  if (newWindow) {
    link.target = "_blank";
  }
  link.download = name;
  link.href = uri;
  link.click();
};

export const openExternalLink = (uri: string, name: string): void => {
  downloadURI(uri, name, true);
};

export const downloadBlobAsFile = (
  data: BlobPart,
  type: string,
  name: string,
): void => {
  const blob = new Blob([data], { type });
  const url = window.URL.createObjectURL(blob);
  downloadURI(url, name);
  window.URL.revokeObjectURL(url);
};
