import * as css from "bundle-text:./tp-result-item-with-image.scss";
import { createStyleSheet } from "../../utils/createStylesheets";

const styleSheets = createStyleSheet(css);

const template = document.createElement("template");
template.innerHTML = `<div class="result-item">
  <div><slot name="heading" /></div>
  <div class="result-container">
    <div class="flex">
      <slot></slot>
    </div>
  </div>
</div>
<slot name="info-box"></slot>

<div class="hr"></div/>`;

class TpResultItems extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    this.shadowRoot.appendChild(template.content.cloneNode(true));
    this.shadowRoot.adoptedStyleSheets = [styleSheets];
  }

  connectedCallback() {
    this.setCustomAttribute();
  }

  setCustomAttribute = () => {
    const headingProperty = this.getAttribute("heading");
    const imageProperty = this.getAttribute("image");

    if (headingProperty) {
      const h2 = document.createElement("h2");
      h2.classList.add("title");
      h2.innerHTML = headingProperty;

      this.shadowRoot.querySelector(".result-container").prepend(h2);
    }

    const imageDiv = document.createElement("div");
    imageDiv.classList.add("image-container");
    if (imageProperty) {
      imageDiv.innerHTML = `<img src="${imageProperty}" />`;
    } else {
      this.shadowRoot.querySelector(".result-item").classList.add("no-image");
    }

    this.shadowRoot.querySelector(".result-item").prepend(imageDiv);
  };
}

export default TpResultItems;

customElements.define("tp-result-item-with-image", TpResultItems);
