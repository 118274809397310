import { useNavigate } from "@solidjs/router";
import { Component, Show } from "solid-js";

import { applicationStore } from "../../../../store";
import { ROUTES } from "../../../../constants/routes";

import burger from "../icon/burger.svg";

const AdminNavigation: Component = () => {
  const navigate = useNavigate();

  const { applicationState } = applicationStore;

  const handleAdminNavigation = (e) => {
    const route = e.detail.value?.toLowerCase();

    if (ROUTES.import.endsWith(route)) {
      navigate(ROUTES.import, { replace: true });
    }

    if (ROUTES.indices.endsWith(route)) {
      navigate(ROUTES.indices, { replace: true });
    }

    if (ROUTES.userManagement.endsWith(route)) {
      navigate(ROUTES.userManagement, { replace: true });
    }
  };

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <Show
        when={
          applicationState.isLoggedIn &&
          applicationState.roles?.includes("ADMIN")
        }
      >
        <div
          id="burger-menu"
          style={{
            position: "relative",
            cursor: "pointer",
            height: "40px",
            top: "8px",
          }}
          onClick={(e) => {
            e.currentTarget.parentElement
              .querySelector("tp-list-menu")
              .dispatchEvent(new Event("menu:open"));
          }}
        >
          <img src={burger} alt="admin menu" height="26" />
        </div>
        <tp-list-menu
          onChange={handleAdminNavigation}
          anchor="burger-menu"
          ref={(el) => (el.items = ["Import", "Indices", "User-Management"])}
        />
      </Show>
    </div>
  );
};

export default AdminNavigation;
