import * as css from "bundle-text:./tp-flags.scss";
import { createStyleSheet } from "../../utils/createStylesheets";
import { deFlag, atFlag, chFlag } from "./icons/flag-icons";

const styleSheets = createStyleSheet(css);

const flags = {
  DE: deFlag,
  AT: atFlag,
  CH: chFlag,
};

const template = document.createElement("template");
template.innerHTML = "<img class='flag'/>";

class TpFlags extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: "open" });

    this.shadowRoot.appendChild(template.content.cloneNode(true));
    this.shadowRoot.adoptedStyleSheets = [styleSheets];
  }

  connectedCallback() {
    const countryProperty = this.getAttribute("country");
    const classProperty = this.getAttribute("theme");

    const imgElement = this.shadowRoot.querySelector("img");
    imgElement.src = `data:image/svg+xml;base64,${btoa(flags[countryProperty])}`;
    imgElement.classList.add(classProperty);
  }
}

export default TpFlags;

customElements.define("tp-flags", TpFlags);
