import "@material/web/checkbox/checkbox";

import * as css from "bundle-text:./tp-checkbox.scss";
import { createStyleSheet } from "../../utils/createStylesheets";

const styleSheets = createStyleSheet(css);

const template = document.createElement("template");
template.innerHTML = `<label class="tp-checkbox">
<md-checkbox touch-target="wrapper"></md-checkbox>
<slot/>
</label>`;

class TpCheckbox extends HTMLElement {
  static get observedAttributes() {
    return ["value", "checked"];
  }

  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    this.shadowRoot.appendChild(template.content.cloneNode(true));
    this.shadowRoot.adoptedStyleSheets = [styleSheets];

    this.$checkbox = this.shadowRoot.querySelector("md-checkbox");
  }

  handleValueProperty() {
    const value = this.getAttribute("value");

    this.$checkbox?.setAttribute("value", value);
  }

  handleIsCheckedProperty() {
    const isChecked = this.getAttribute("checked");
    if (isChecked === "true") {
      this.$checkbox.setAttribute("checked", "");
    } else {
      this.$checkbox.removeAttribute("checked");
    }
  }

  connectedCallback() {
    for (const attribute of this.attributes) {
      this.$checkbox.setAttribute(attribute.name, attribute.value);
    }
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (oldValue === newValue) return;
    if (name === "value") {
      this.handleValueProperty();
    }

    if (name === "checked") {
      this.handleIsCheckedProperty();
    }
  }
}

export default TpCheckbox;

customElements.define("tp-checkbox", TpCheckbox);
