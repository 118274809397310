import { Component, Show, createEffect, createSignal } from "solid-js";
import { createStore } from "solid-js/store";
import { useNavigate } from "@solidjs/router";

import { topicsStore } from "../../../store";
import { applicationStore } from "../../../store/";

import { formateDateString, getToday } from "../../../utils/date-utils";

import { ROUTES } from "../../../constants/routes";
import { saveSearch } from "../../../services/api/search-api-handler";

import {
  mapCountriesToGerman,
  mapStoreItemToBackendModel,
} from "../../../services/data-mapper";

import "./saved-searches-row.scss";

import deleteIconPath from "../../shared/icons/delete.svg";
import saveIconPath from "../../shared/icons/save.svg";
import openIconPath from "../../shared/icons/open.svg";
import editIconPath from "../../shared/icons/edit.svg";
import cancelIconPath from "../../shared/icons/cancel.svg";

const SavedSearchesRow: Component = (props) => {
  const { setApplicationState } = applicationStore;
  const { topicsState } = topicsStore;
  const [isEditMode, setIsEditMode] = createSignal(false);

  const showButtons = props["show-buttons"] || false;

  const navigate = useNavigate();

  const [rowStore, setRowStore] = createStore({
    author: "",
    fileName: "",
    type: "Redaktionelle Themen",
    updateTime: getToday(),
  });

  createEffect(() => {
    if (props.search) {
      setRowStore({
        author: props?.search?.urheber,
        fileName: props?.search?.bezeichnung,
        type: props?.search?.type,
        updateTime: props?.search?.updateTime,
      });
    }
  });

  const showErrorOnEmptyFields = () => {
    let validFields = true;
    const authorField = document.getElementById("author");
    const fileNameField = document.getElementById("fileName");

    if (rowStore.author === "") {
      authorField.setAttribute("error", "");
      validFields = false;
    }

    if (rowStore.fileName === "") {
      fileNameField.setAttribute("error", "");
      validFields = false;
    }

    return validFields;
  };

  const onSave = async () => {
    if (showErrorOnEmptyFields() === false) return;

    const data = {
      bezeichnung: rowStore.fileName,
      urheber: rowStore.author,
      type: "themen", // hard coded
      suchoptionen: {
        ...mapStoreItemToBackendModel(topicsState),
        land: mapCountriesToGerman(topicsState.countries),
      },
    };
    await saveSearch(data);

    setRowStore("author", "");
    setRowStore("fileName", "");

    document.getElementById("author").removeAttribute("error");
    document.getElementById("fileName").removeAttribute("error");
    setApplicationState("showCollapsibleContent", false);
  };

  const onButtonSave = () => {
    if (showErrorOnEmptyFields() === false) return;

    const fields = {
      ...props.search,
      bezeichnung: rowStore.fileName,
      urheber: rowStore.author,
    };

    props.handleSave(fields);
  };

  const handleDeleteClick = () => {
    const {
      handleDelete,
      search: { id },
    } = props;
    handleDelete(id, rowStore.fileName);
  };

  const editSearch = () => {
    setIsEditMode((isEditMode) => !isEditMode);
  };

  const setDisabled = () => ({
    ...(!isEditMode() && showButtons === false
      ? { "attr:disabled": true }
      : {}),
  });

  return (
    <>
      <div class="saved-searches-row">
        <tp-text-field
          id="fileName"
          appearance="light"
          label="Dateiname"
          error-text="Bitte ausfüllen"
          required
          attr:value={rowStore.fileName}
          onInput={(e) => setRowStore("fileName", e.target.value)}
          {...setDisabled()}
        />

        <tp-text-field
          id="author"
          appearance="light"
          label="Urheber"
          error-text="Bitte ausfüllen"
          required
          attr:value={rowStore.author}
          onInput={(e) => setRowStore("author", e.target.value)}
          {...setDisabled()}
        />

        <tp-text-field
          id="category"
          appearance="light"
          label="Kategorie"
          required
          value="Redaktionsthemen"
          disabled="true"
        />

        <tp-text-field
          id="date"
          appearance="light"
          label="Datum"
          disabled="true"
          attr:value={formateDateString(rowStore.updateTime)}
        />

        <Show
          when={showButtons === false}
          fallback={
            <div class="actions">
              <tp-button onClick={onSave} appearance="secondary">
                SPEICHERN
              </tp-button>
              <tp-text-button
                onClick={() => navigate(ROUTES.root, { replace: true })}
                style={{ "margin-left": "10px" }}
              >
                VERWERFEN
              </tp-text-button>
            </div>
          }
        >
          <div class="icons">
            {isEditMode() ? (
              <img
                src={saveIconPath}
                height="32"
                alt="Speichern"
                onClick={onButtonSave}
                title="Änderung speichern"
              />
            ) : (
              <img
                src={openIconPath}
                height="32"
                alt="Öffnen"
                onClick={() => props.handleOpenSearch(props.search)}
                title="Suche öffnen"
              />
            )}

            {!isEditMode() ? (
              <img
                src={editIconPath}
                height="28"
                alt="Editieren"
                onClick={editSearch}
              />
            ) : (
              <img
                src={cancelIconPath}
                height="28"
                alt="Schließen"
                onClick={editSearch}
              />
            )}
            <img
              src={deleteIconPath}
              height="32"
              alt="Löschen"
              onClick={handleDeleteClick}
              title="Suche löschen"
            />
          </div>
        </Show>
      </div>
    </>
  );
};

export default SavedSearchesRow;
