import "@material/web/switch/switch";
import * as css from "bundle-text:./tp-switch.scss";
import { createStyleSheet } from "../../utils/createStylesheets";

const styleSheets = createStyleSheet(css);

const template = document.createElement("template");
template.innerHTML = `<label class="tp-switch">
<slot></slot>
<md-switch></md-switch>
</label>`;

class TpSwitch extends HTMLElement {
  static get observedAttributes() {
    return ["selected"];
  }

  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    this.shadowRoot.appendChild(template.content.cloneNode(true));
    this.shadowRoot.adoptedStyleSheets = [styleSheets];
  }

  connectedCallback() {
    this.$element = this.shadowRoot.querySelector("md-switch");
    for (const attribute of this.attributes) {
      this.$element.setAttribute(attribute.name, attribute.value);
    }
  }

  handlePropertyWithoutValue(propertyName) {
    const propertyWithoutValue = this.getAttribute(propertyName);
    if (propertyWithoutValue !== null && propertyWithoutValue !== undefined) {
      this.$element?.setAttribute(propertyName, "");
    }

    if (propertyWithoutValue === "false") {
      this.$element?.removeAttribute(propertyName);
    }
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (oldValue === newValue) return;

    if (name === "selected") {
      this.handlePropertyWithoutValue("selected");
    }

    if (name === "disabled") {
      this.handlePropertyWithoutValue("disabled");
    }
  }
}

export default TpSwitch;

customElements.define("tp-switch", TpSwitch);
