import { Component, Show } from "solid-js";

import { influencerStore } from "../../../store";

import sort_icon from "../../shared/icons/sort.svg";
import { influencerCategories } from "../influencer.route";

const sortItems = {
  influencer: ["Name", "Follower", "Ort"],
  freiejournalisten: ["Name", "Ort"],
};

const InfluencerResultToolbar: Component = (props) => {
  const { influencerState, setInfluencerState } = influencerStore;

  const openSortMenu = (e) => {
    const clickedTag = e.target.tagName;

    // hack because if you click multiple times on the
    // opening button, the menulist will close and never open
    // until you click elsewhere
    if (clickedTag === "SPAN" || clickedTag === "IMG") {
      const menuList = e.currentTarget.querySelector("tp-list-menu");

      menuList.items =
        influencerState.category === influencerCategories.INFLUENCER
          ? sortItems.influencer
          : sortItems.freiejournalisten;

      menuList.dispatchEvent(new Event("menu:open"));
    }
  };

  const handleSort = async (event) => {
    try {
      const value = event.detail.value;

      if (value) {
        setInfluencerState("sort", value.toUpperCase());
        await props?.handleSearch();
      }
    } catch (parseError) {
      console.log(parseError);
    }
  };

  return (
    <Show when={influencerState.totalHits > 0}>
      <tp-result-toolbar attr:result={`${influencerState.totalHits} Treffer`}>
        <tp-text-button
          id="sort-button-influencer"
          class="mdc-menu-surface--anchor"
          onClick={openSortMenu}
        >
          <img src={sort_icon} height="28" />
          <span>SORTIEREN</span>

          <tp-list-menu onChange={handleSort} anchor="sort-button-fairs" />
        </tp-text-button>
      </tp-result-toolbar>
    </Show>
  );
};
export default InfluencerResultToolbar;
