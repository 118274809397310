// https://webdesign.tutsplus.com/tutorials/how-to-implement-infinite-scrolling-with-javascript--cms-37055

let throttleTimer: boolean;
const throttle = (callback: () => void, time: number): void => {
  if (throttleTimer) return;
  throttleTimer = true;
  setTimeout(() => {
    callback();
    throttleTimer = false;
  }, time);
};

export const handleInfiniteScroll = (callback: () => void) => () => {
  throttle(() => {
    const marginBottom = 100;
    const endOfPage =
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - marginBottom;

    if (endOfPage) {
      callback();
    }
  }, 800);
};
