import * as css from "bundle-text:./tp-help-tip.scss";
import { createStyleSheet } from "../../utils/createStylesheets";

const styleSheets = createStyleSheet(css);

const template = document.createElement("template");
template.innerHTML = `<div class="tp-help-tip">
<h2 class="title"></h2>
<div class="content"><slot/></div>
<div class="tip do"></div>
<div class="tip dont"><span class="dont"> Suchbegriffe: </span></div>
</div>`;

class TpHelpTip extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    this.shadowRoot.appendChild(template.content.cloneNode(true));
    this.shadowRoot.adoptedStyleSheets = [styleSheets];
  }

  connectedCallback() {
    const titleValue = this.getAttribute("title");
    const doValue = this.getAttribute("do");
    const dontValue = this.getAttribute("dont");

    this.shadowRoot.querySelector("h2").textContent = titleValue;
    this.shadowRoot.querySelector(".do").innerHTML =
      `<span class="do">&#9632;</span> Suchbegriffe: ${doValue}`;
    this.shadowRoot.querySelector(".dont").innerHTML =
      `<span class="dont">&#9632;</span> Suchbegriffe: ${dontValue}`;
  }
}

export default TpHelpTip;

customElements.define("tp-help-tip", TpHelpTip);
