import { createEffect } from "solid-js";
import { applicationStore } from "../../../store";
import { fetchMetaInfos } from "../../../services/api/meta-data-api-handler";
import { isSessionTokenValid } from "../../../services/api/session-api-handler";
import { clearToken, setToken } from "../../../utils/auth-token";

const { applicationState, setApplicationState } = applicationStore;

export const loggedIn = async (username: string, token: string) => {
  setToken(token);
  sessionStorage.setItem("ls.auth_username", username);
  createEffect(() => {
    setApplicationState("isLoggedIn", true);
  });

  const { currentData, notesStatsData } = await fetchMetaInfos();

  setApplicationState(
    "notesInProgress",
    notesStatsData["by-bearbeitungsstatus"]["IN_BEARBEITUNG"],
  );
  setApplicationState("roles", currentData?.roles);
  setApplicationState("whitelabel", currentData?.whitelabel);
  setApplicationState(
    "visibleModules",
    currentData?.subscriptions?.visibleModules || [],
  );
};

export const updateApplicationState = async () => {
  if ((await isSessionTokenValid()) === false) {
    clearToken();
    sessionStorage.removeItem("ls.auth_username");
    setApplicationState("isLoggedIn", false);
    setApplicationState("whitelabel", false);
    setApplicationState("visibleModules", []);
  } else {
    createEffect(() => {
      setApplicationState("isLoggedIn", true);
    });

    const { currentData, notesStatsData } = await fetchMetaInfos();

    setApplicationState(
      "notesInProgress",
      notesStatsData["by-bearbeitungsstatus"]["IN_BEARBEITUNG"],
    );
    setApplicationState("roles", currentData?.roles);
    setApplicationState("whitelabel", currentData?.whitelabel);
    setApplicationState(
      "visibleModules",
      currentData?.subscriptions?.visibleModules || [],
    );
  }
};

export const isModuleVisible = (module: string) => {
  return (
    !applicationState.whitelabel ||
    applicationState.visibleModules.length === 0 ||
    applicationState.visibleModules.includes(module)
  );
};

export const isModuleSubscribed = (module: string) => {
  return (
    /* TODO consider _not_ allowing the SUPERUSER to use everything */
    applicationState.roles?.includes("SUPERUSER") ||
    (applicationState.visibleModules.length > 0 &&
      applicationState.visibleModules.includes(module))
  );
};
