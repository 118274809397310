import { MDCSnackbar } from "@material/snackbar";

import * as css from "bundle-text:./tp-snackbar.scss";
import { createStyleSheet } from "../../utils/createStylesheets";

const styleSheets = createStyleSheet(css);

const template = document.createElement("template");
template.innerHTML = `<aside class="tp-snackbar mdc-snackbar">
  <div class="mdc-snackbar__surface" role="status" aria-relevant="additions">
    <div class="mdc-snackbar__label" aria-atomic="false">
      <div class="centered"><slot/></div>
    </div>
  </div>
</aside>`;

class TpSnackbar extends HTMLElement {
  constructor() {
    super();

    this.attachShadow({ mode: "open" });
    this.shadowRoot.appendChild(template.content.cloneNode(true));
    this.shadowRoot.adoptedStyleSheets = [styleSheets];

    this.snackbar = new MDCSnackbar(
      this.shadowRoot.querySelector(".mdc-snackbar"),
    );
  }

  connectedCallback() {
    this.addEventListener("snackbar:open", () => {
      this.snackbar.open();
    });
  }

  disconnectedCallback() {
    this.removeEventListener("snackbar:open", null);
  }
}

export default TpSnackbar;

customElements.define("tp-snackbar", TpSnackbar);
