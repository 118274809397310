import * as css from "bundle-text:./tp-footer.scss";
import { createStyleSheet } from "../../utils/createStylesheets";

const styleSheets = createStyleSheet(css);

const defaultTemplate = document.createElement("template");
defaultTemplate.innerHTML = `<footer id="page-footer">
  <div class="footer--copyright">
     © 2024 <a href="https://www.mountbarley.de/">Mount Barley Publishing</a>
  </div>
  <div class="footer--links">
    <ul>
      <li><a href="https://topicpro.mountbarley.de/">Produktinformationen</a></li>
      <li><a href="https://hilfe.mountbarley.de/">FAQs</a></li>
      <li><a href="https://www.mountbarley.de/impressum.htm">Impressum</a></li>
    </ul>
  </div>
</footer>`;

const whitelabelTemplate = document.createElement("template");
whitelabelTemplate.innerHTML = `<footer id="page-footer">
  <div class="footer--copyright">
      © 2024 <a href="https://www.mountbarley.de/" target="_blank">Mount Barley Publishing</a>
  </div>
  <div class="footer--links">
    <ul>
      <li><a href="https://hilfe.mountbarley.de/" target="_blank">Diese Suchabfrage erfolgt in der Themen- und Mediendatenbank TOPIC PRO.</a></li>
    </ul>
  </div>
</footer>`;

class TpFooter extends HTMLElement {
  static get observedAttributes() {
    return ["variant"];
  }

  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    this.shadowRoot.adoptedStyleSheets = [styleSheets];
  }

  connectedCallback() {
    this.updateFooter();
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (oldValue === newValue) return;
    this.updateFooter();
  }

  updateFooter() {
    const variant = this.getAttribute("variant");
    if (variant === "whitelabel") {
      this.shadowRoot.replaceChildren(
        whitelabelTemplate.content.cloneNode(true),
      );
    } else {
      this.shadowRoot.replaceChildren(defaultTemplate.content.cloneNode(true));
    }
  }
}

customElements.define("tp-footer", TpFooter);
