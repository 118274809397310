import { createContext, useContext, Component } from "solid-js";
import { applicationStore, PressetextType } from "./index";
import { fetchSavedPressetexte } from "../services/api/search-api-handler";
import { createResource, Resource } from "solid-js";

type PressetexteStore = [Resource<PressetextType[]>, { refetch?: () => void }];

const PressetexteContext = createContext<PressetexteStore>([undefined, {}]);

export const PressetexteProvider: Component<{ children: Element[] }> = (
  props,
) => {
  async function fetchAllPressetexte(): Promise<PressetextType[]> {
    const { setApplicationState } = applicationStore;
    const response = await fetchSavedPressetexte();
    if (response === null || response === undefined) return;
    const body = await response.json();
    setApplicationState(
      "pressetexteCount",
      // TODO we're filtering here and at other places, too. Make it DRY.
      body?.filter((p) => !p.draft).length,
    );
    return body;
  }

  const [state, { refetch }] = createResource(fetchAllPressetexte),
    store: PressetexteStore = [
      state,
      {
        refetch,
      },
    ];

  return (
    <PressetexteContext.Provider value={store}>
      {props.children}
    </PressetexteContext.Provider>
  );
};

export function usePressetexte() {
  return useContext(PressetexteContext);
}
