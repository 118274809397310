import { Component, Show, createMemo } from "solid-js";
import { A, useLocation } from "@solidjs/router";
import { ROUTES } from "../../../constants/routes";

import { applicationStore } from "../../../store";
import { isModuleSubscribed, isModuleVisible } from "../login/session";

import { COLLAPSIBLE_CONTENT } from "../../../constants";
import { showToggledContent } from "./utils/navigation.utils";

const Navigation: Component = () => {
  const location = useLocation();
  const currentPath = createMemo(() => location.pathname);

  const { applicationState } = applicationStore;

  const isCollapsibleContentActive = (content) =>
    applicationState.collapsibleContent === content &&
    applicationState.showCollapsibleContent === true;

  return (
    <>
      <tp-nav>
        <Show when={isModuleVisible("THEMEN")}>
          <tp-nav-item
            attr:active={
              currentPath() === ROUTES.topics ||
              currentPath() === ROUTES.default
                ? "true"
                : "false"
            }
            {...(applicationState.showCollapsibleContent === true
              ? { "attr:theme": "secondary" }
              : {})}
          >
            <A href={ROUTES.topics}>REDAKTIONSTHEMEN</A>
          </tp-nav-item>
        </Show>

        <Show when={isModuleVisible("MESSEN")}>
          <tp-nav-item
            attr:active={currentPath() === ROUTES.fairs ? "true" : "false"}
            {...(applicationState.showCollapsibleContent === true
              ? { "attr:theme": "secondary" }
              : {})}
          >
            <A href={ROUTES.fairs}>MESSEN/THEMENTAGE</A>
          </tp-nav-item>
        </Show>

        <Show when={isModuleVisible("SOCIAL")}>
          <tp-nav-item
            attr:active={currentPath() === ROUTES.socialNews ? "true" : "false"}
            {...(applicationState.showCollapsibleContent === true
              ? { "attr:theme": "secondary" }
              : {})}
          >
            <A href={ROUTES.socialNews}>SOCIAL NEWS</A>
          </tp-nav-item>
        </Show>

        <Show when={isModuleVisible("INFLUENCER")}>
          <tp-nav-item
            attr:active={currentPath() === ROUTES.influencer ? "true" : "false"}
            {...(applicationState.showCollapsibleContent === true
              ? { "attr:theme": "secondary" }
              : {})}
          >
            <A href={ROUTES.influencer}>INFLUENCER</A>
          </tp-nav-item>
        </Show>

        <div slot="right">
          <Show when={applicationState.isLoggedIn && isModuleVisible("SUCHEN")}>
            <tp-nav-item
              theme="secondary"
              attr:active={isCollapsibleContentActive(
                COLLAPSIBLE_CONTENT.SEARCHES,
              )}
              {...(applicationState.searches > 0
                ? { "attr:counter": applicationState.searches }
                : {})}
              style={{ "margin-right": "10px" }}
            >
              <a
                href={"#no"}
                onClick={() => showToggledContent(COLLAPSIBLE_CONTENT.SEARCHES)}
              >
                Meine Suchen
              </a>
            </tp-nav-item>
          </Show>

          <Show
            when={applicationState.isLoggedIn && isModuleVisible("PRESSETEXTE")}
          >
            <tp-nav-item
              theme="secondary"
              attr:active={isCollapsibleContentActive(
                COLLAPSIBLE_CONTENT.PRESSETEXTE,
              )}
              {...(applicationState.pressetexteCount > 0
                ? { "attr:counter": applicationState.pressetexteCount }
                : {})}
              style={{ "margin-right": "10px" }}
            >
              <a
                href={"#no"}
                onClick={() => {
                  if (!isModuleSubscribed("PRESSETEXTE")) {
                    const dialog = document.getElementById(
                      "not-subscribed-dialog",
                    );
                    dialog.dispatchEvent(
                      new Event("not-subscribed:dialog:open"),
                    );
                  } else {
                    showToggledContent(COLLAPSIBLE_CONTENT.PRESSETEXTE);
                  }
                }}
              >
                Pressetexte
              </a>
            </tp-nav-item>
          </Show>

          <Show
            when={applicationState.isLoggedIn && isModuleVisible("NOTIZEN")}
          >
            <tp-nav-item
              attr:active={isCollapsibleContentActive(
                COLLAPSIBLE_CONTENT.NOTES,
              )}
              theme="secondary"
              {...(applicationState.notesInProgress > 0
                ? { "attr:counter": applicationState.notesInProgress }
                : {})}
            >
              <a
                href={"#no"}
                onClick={() => showToggledContent(COLLAPSIBLE_CONTENT.NOTES)}
              >
                Notizen
              </a>
            </tp-nav-item>
          </Show>
          <tp-nav-item
            attr:active={currentPath() === ROUTES.help ? "true" : "false"}
            style={{ "margin-left": "10px" }}
          >
            <a
              href={"#no"}
              onClick={() => showToggledContent(COLLAPSIBLE_CONTENT.HELP)}
            >
              {isCollapsibleContentActive(COLLAPSIBLE_CONTENT.HELP) ? (
                <b>?</b>
              ) : (
                "?"
              )}
            </a>
          </tp-nav-item>
        </div>
      </tp-nav>
    </>
  );
};

export default Navigation;
