import * as css from "bundle-text:./tp-collapsible-box.scss";
import { createStyleSheet } from "../../utils/createStylesheets";
import { closeIcon } from "./icons/close";

const styleSheets = createStyleSheet(css);

const template = document.createElement("template");
template.innerHTML = `<div class="tp-collapsible-box">
  <div class="header">
    <h4 class="title"></h4>
    <img src="data:image/svg+xml;base64,${btoa(
      closeIcon,
    )}" alt="close" height="28"/>
  </div>
  <div class="content"><slot/></div>
</div>`;

class TpCollapsibleBox extends HTMLElement {
  static get observedAttributes() {
    return ["title"];
  }

  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    this.shadowRoot.appendChild(template.content.cloneNode(true));
    this.shadowRoot.adoptedStyleSheets = [styleSheets];

    this.collapsibleBox = this.shadowRoot.querySelector(".tp-collapsible-box");

    this.shadowRoot
      .querySelector(".header img")
      .addEventListener("click", () => {
        this.collapsibleBox.classList.remove("collapsed");
      });

    this.addEventListener(`collapsible:show`, () => {
      if (!this.collapsibleBox.classList.contains("collapsed")) {
        this.collapsibleBox.classList.toggle("collapsed");
      }
    });
  }

  connectedCallback() {
    const titleProperty = this.getAttribute("title");
    this.shadowRoot.querySelector(".title").textContent = titleProperty;
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (oldValue === newValue) return;

    if (name === "title") {
      this.shadowRoot.querySelector(".title").textContent =
        this.getAttribute("title");
    }
  }
}

export default TpCollapsibleBox;

customElements.define("tp-collapsible-box", TpCollapsibleBox);
