import { checkTokenValidity, token } from "../../utils/auth-token";

const SESSION_URLS = {
  LOGIN: "/api/login",
  VERIFY_TOKEN: "/token/verify/",
};

export const fetchLogin = async (loginData) => {
  const url = `${process.env.API_URL}${SESSION_URLS.LOGIN}`;
  return await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(loginData),
  });
};

export const isSessionTokenValid = async () => {
  if (checkTokenValidity() === false) {
    return false;
  }

  const url = `${process.env.API_URL}${SESSION_URLS.VERIFY_TOKEN}${token()}`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "x-auth-token": token(),
    },
  });

  return response.ok;
};
