export const ROUTES = {
  root: "/",
  ssi: "/ssi/:token",
  topics: "/themen",
  media: "/medien",
  fairs: "/messen",
  days: "/thementage",
  socialNews: "/social-news",
  influencer: "/influencer",
  default: "/",
  passwordForget: "/account/forgot-password",
  passwordReset: "/account/reset-password",
  activation: "/account/activation/:id",
  help: "/hilfe",
  import: "admin/import",
  indices: "admin/indices",
  userManagement: "/admin/user-management",
  userManagementEdit: "admin/user-management/:id",
  userManagementNew: "/admin/user-management/new",
};

export const HOMEPAGE_URL = "https://www.mountbarley.de/index.html";
export const PRICING_PAGE_URL = "https://www.mountbarley.de/index.html#preise";
export const HOMEPAGE_URL_REGISTER =
  "https://www.topicpro-kostenlos-testen.de/";
export const HOMEPAGE_URL_UPSELL = "https://www.mountbarley.de/upgrade.html";

export const NAVIGATION_INTENT = {
  INITIAL: "initial", // the route is being initially shown
  NAVIGATE: "navigate", //  navigate originated from the browser //back-forward
  NATIVE: "native", // navigate originated from the router (eg call to navigate or anchor clicked)
  PRELOAD: "preload", //  not navigating, just preloading (eg link hover)
};
