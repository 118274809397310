import "@material/web/select/outlined-select";
import "@material/web/select/select-option";

import * as cssText from "bundle-text:./tp-select.scss";
import { createStyleSheet } from "../../utils/createStylesheets";

const styleSheets = createStyleSheet(cssText);

const template = document.createElement("template");
template.innerHTML = `<md-outlined-select class="tp-select"></md-outlined-select>`;

class TpSelect extends HTMLElement {
  static get observedAttributes() {
    return ["items", "selected", "disabled", "required"];
  }

  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    this.shadowRoot.appendChild(template.content.cloneNode(true));
    this.shadowRoot.adoptedStyleSheets = [styleSheets];
  }

  connectedCallback() {
    this.$element = this.shadowRoot.querySelector("md-outlined-select");

    this.handleProperty("label");
    this.handlePropertyWithoutValue("required");
    this.handlePropertyWithoutValue("disabled");
    this.handlePropertyWithoutValue("selected");
    this.handleItems();
    this.addEventHandler();

    setTimeout(() => {
      this.shadowRoot
        .querySelector("md-outlined-select")
        .shadowRoot.querySelector("md-outlined-field")
        .shadowRoot.querySelector(".outline-label")
        .remove();

      this.shadowRoot
        .querySelector("md-outlined-select")
        .shadowRoot.querySelector("md-outlined-field")
        .shadowRoot.querySelector(".end")
        .remove();
    }, 100);
  }

  addEventHandler() {
    const eventHandler = (e) => {
      this.dispatchEvent(
        new CustomEvent("change", {
          detail: {
            value: e.target.value,
          },
        }),
      );
    };

    this.$element.addEventListener("change", eventHandler);
  }

  handleItems() {
    if (!this.$element) return;

    this.$element = this.shadowRoot.querySelector("md-outlined-select");
    this.$element.innerHTML = "";

    const optionItems = this.getAttribute("items");
    const selectedProp = this.getAttribute("selected");

    try {
      const items = JSON.parse(optionItems);
      if (items && items.length > 0) {
        items.forEach((item) => {
          const optionTag = document.createElement("md-select-option");
          optionTag.setAttribute("value", item.value || item.label);

          if (selectedProp === item.value || selectedProp === item.label) {
            optionTag.setAttribute("selected", "true");
          } else {
            optionTag.removeAttribute("selected");
          }

          const optionTagSlot = document.createElement("div");
          optionTagSlot.setAttribute("slot", "headline");
          optionTagSlot.innerHTML = item.label || item.value;
          optionTag.appendChild(optionTagSlot);

          this.$element.appendChild(optionTag);
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  handleProperty(propertyType) {
    const property = this.getAttribute(propertyType);
    if (property !== null && property !== undefined && property !== "") {
      this.$element?.setAttribute(propertyType, property);
    }
  }

  handlePropertyWithoutValue(propertyName) {
    const propertyWithoutValue = this.getAttribute(propertyName);
    if (propertyWithoutValue !== null && propertyWithoutValue !== undefined) {
      this.$element?.setAttribute(propertyName, "");
    }

    if (propertyWithoutValue === "false") {
      this.$element?.removeAttribute(propertyName);
    }
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (oldValue === newValue) return;

    switch (name) {
      case "selected":
        this.handleItems();
        // stupid md3 select hack
        setTimeout(() => {
          this.shadowRoot.querySelector("md-outlined-select").select(newValue);
        }, 300);

        break;

      case "label":
        this.handleProperty("label");
        break;

      case "disabled":
        this.handlePropertyWithoutValue("disabled");
        break;

      case "required":
        this.handlePropertyWithoutValue("required");
        break;

      case "items":
        this.handleItems();
        break;

      default:
        break;
    }
  }
}

export default TpSelect;

customElements.define("tp-select", TpSelect);
