import { Component, createEffect, createSignal, onMount } from "solid-js";

import deleteIconPath from "../../shared/icons/delete.svg";
import saveIconPath from "../../shared/icons/save.svg";
import openEyeconPath from "./icons/eye-open.svg";
import closedEyeIconPath from "./icons/eye-closed.svg";
import editIconPath from "../../shared/icons/edit.svg";
import cancelIconPath from "../../shared/icons/cancel.svg";

import "./note-row.scss";
import { formateDateString, getToday } from "../../../utils/date-utils";
import { createStore } from "solid-js/store";
import { NOTES_STATUS } from "../../../constants";

const NoteRow: Component = (props) => {
  const [isCommentBlockOpen, setIsCommentBlockOpen] = createSignal(false);
  const [isEditMode, setIsEditMode] = createSignal(false);
  let eyeIcon;

  const [rowStore, setRowStore] = createStore({
    bearbeitungsstatus: "",
    body: "",
    urheber: "",
    titel: "",
    updateTime: getToday(),
  });

  onMount(() => {
    if (props?.opened === "true") {
      setIsCommentBlockOpen(true);
    }
  });

  createEffect(() => {
    if (props.note) {
      setRowStore(props.note);
    }
    if (props.isEditMode === "true") {
      setIsEditMode(true);
    }
  });

  const toggleNoteBlock = () => {
    let src = eyeIcon.getAttribute("src");

    if (src === openEyeconPath) {
      src = closedEyeIconPath;
    } else {
      src = openEyeconPath;
    }

    eyeIcon.setAttribute("src", src);

    setIsCommentBlockOpen((isCommentBlockOpen) => {
      return !isCommentBlockOpen;
    });
  };

  const setStatus = async (event) => {
    setRowStore("bearbeitungsstatus", event.detail.value);
  };

  const editNote = () => {
    setIsEditMode((isEditMode) => !isEditMode);
    toggleNoteBlock();
  };

  const setDisabled = () => ({
    ...(!isEditMode() ? { "attr:disabled": true } : {}),
  });

  const handleSave = (event) => {
    const titleField = document.getElementById("new-note-name");
    const authorField = document.getElementById("new-note-author");

    titleField.removeAttribute("error");
    authorField.removeAttribute("error");

    if (rowStore.titel === "") {
      titleField.setAttribute("error", "");
      return;
    }

    if (rowStore.urheber === "") {
      authorField.setAttribute("error", "");
      return;
    }

    props.handleEdit(rowStore, event);
  };

  return (
    <div class="notes-row-container">
      <div class="notes-row">
        <tp-text-field
          id="new-note-name"
          appearance="light"
          label="Titel"
          required
          attr:value={rowStore.titel}
          onInput={(e) => setRowStore("titel", e.target.value)}
          {...setDisabled()}
        />
        <tp-text-field
          id="new-note-author"
          appearance="light"
          label="Urheber"
          required
          attr:value={rowStore.urheber}
          onInput={(e) => setRowStore("urheber", e.target.value)}
          {...setDisabled()}
        />

        {!isEditMode() ? (
          <tp-text-field
            id="bearbeitung"
            appearance="light"
            label="Status"
            attr:value={NOTES_STATUS[rowStore.bearbeitungsstatus]}
            disabled="true"
          />
        ) : (
          <tp-select
            appearance="light"
            select-id="status"
            label="Status"
            attr:selected={rowStore.bearbeitungsstatus}
            items='[{"label":"In Bearbeitung", "value":"IN_BEARBEITUNG"},{"label":"Erledigt", "value":"ERLEDIGT"}]'
            onChange={(e) => setStatus(e)}
          />
        )}

        <tp-text-field
          id="note-change-date"
          appearance="light"
          label="Änderungsdatum"
          required
          attr:value={formateDateString(rowStore.updateTime)}
          disabled="true"
        />
        <div class="icons">
          {isEditMode() ? (
            <img
              src={saveIconPath}
              height="28"
              alt="Speichern"
              onClick={(event) => handleSave(event)}
            />
          ) : (
            <img
              src={openEyeconPath}
              ref={eyeIcon}
              height="28"
              alt="Öffnen"
              onClick={toggleNoteBlock}
            />
          )}

          {isEditMode() ? (
            <img
              src={cancelIconPath}
              height="28"
              alt="Schließen"
              onClick={editNote}
            />
          ) : (
            <img
              src={editIconPath}
              height="28"
              alt="Editieren"
              onClick={editNote}
            />
          )}

          <img
            src={deleteIconPath}
            height="28"
            alt="Löschen"
            onClick={(e) => props.handleDelete(e, rowStore.id)}
          />
        </div>
      </div>
      <div
        class={`textarea ${
          isCommentBlockOpen() || isEditMode() ? "" : "hidden"
        }`}
      >
        <tp-text-field
          appearance="light"
          type="textarea"
          attr:value={rowStore.body}
          label="Notiz"
          {...setDisabled()}
          onInput={(e) => setRowStore("body", e.target.value)}
        />
      </div>
    </div>
  );
};

export default NoteRow;
