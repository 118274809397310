import "@material/web/progress/circular-progress";

import * as css from "bundle-text:./tp-circular-progress.scss";
import { createStyleSheet } from "../../utils/createStylesheets";

const styleSheets = createStyleSheet(css);

const template = document.createElement("template");
template.innerHTML = `<div class="tp-circular-progress"><md-circular-progress class="tp-circurlar-progress" four-color indeterminate></md-circular-progress></div>`;

class TpCircularProgress extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    this.shadowRoot.appendChild(template.content.cloneNode(true));
    this.shadowRoot.adoptedStyleSheets = [styleSheets];
  }
}

export default TpCircularProgress;

customElements.define("tp-circular-progress", TpCircularProgress);
