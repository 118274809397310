import { useNavigate, useParams, useSearchParams } from "@solidjs/router";
import { Component, onMount } from "solid-js";
import { loggedIn } from "../shared/login/session";

const Ssi: Component = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  onMount(async () => {
    const { token } = params;
    if (!(token === null || token === undefined)) {
      await loggedIn(searchParams.username, token);
    }
    navigate("/", { replace: true });
  });

  return <></>;
};

export default Ssi;
