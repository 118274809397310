import {
  Component,
  For,
  createEffect,
  createResource,
  createSignal,
  onMount,
} from "solid-js";

import {
  fetchNotes,
  fetchNote,
  fetchNotesStats,
} from "../../services/api/meta-data-api-handler";
import NoteRow from "./note-row/note-row";
import { applicationStore } from "../../store";
import { token } from "../../utils/auth-token";

async function fetchAllNotes() {
  const response = await fetchNotes();

  if (response) {
    return response.json();
  }
}

const { setApplicationState } = applicationStore;
const setNotesInHeader = async () => {
  const response = await fetchNotesStats();
  if (response.ok) {
    const data = await response.json();
    setApplicationState(
      "notesInProgress",
      data["by-bearbeitungsstatus"]["IN_BEARBEITUNG"],
    );
  }
};

const Notes: Component = () => {
  let dialog;
  const [noteField, setNoteField] = createSignal([]);
  const [deleteNoteId, setDeleteNoteId] = createSignal("");

  const [notes, { refetch }] = createResource(fetchAllNotes);

  onMount(() => {
    dialog = document.getElementById("delete-note-dialog");
  });

  createEffect(() => {
    if (token() !== null) {
      refetch();
    }
  });

  const closeDialog = () => {
    dialog.dispatchEvent(new Event("delete-note:dialog:close"));
  };

  const handleDeleteDialog = async (event, id) => {
    setDeleteNoteId(id);

    const name = notes().filter((item) => item.id === deleteNoteId())?.[0]
      .titel;
    dialog.querySelector("p").innerText =
      `Wollen Sie die gespeicherte Suche "${name}" unwiderruflich löschen?`;
    dialog.dispatchEvent(new Event("delete-note:dialog:open"));
  };

  const handleDelete = async () => {
    const response = await fetchNote("DELETE", deleteNoteId());
    if (response.ok) {
      refetch();
      setDeleteNoteId("");
      closeDialog();
      await setNotesInHeader();
    }
  };

  const handleEdit = async (note) => {
    const response = await fetchNote("PUT", note.id, note);
    if (response.ok) {
      refetch();
    }
  };

  const getClickedNoteRow = (e) => {
    const newElementNodes = e.currentTarget.closest(".new-elements").children;
    const currentNodeParent = e.currentTarget.closest(".notes-row").parentNode;
    const clickedIndex = [...newElementNodes].indexOf(currentNodeParent);

    return clickedIndex;
  };

  const handleSave = async (note, e) => {
    const clickedIndex = getClickedNoteRow(e);

    const response = await fetchNote("POST", "", note);
    if (response.ok) {
      setNoteField([...noteField().toSpliced(clickedIndex, 1)]);
      refetch();
      await setNotesInHeader();
    }
  };

  const deleteRow = (e) => {
    const clickedIndex = getClickedNoteRow(e);
    setNoteField([...noteField().toSpliced(clickedIndex, 1)]);
  };

  const addNewRow = () => {
    const newNote = {
      updateTime: new Date().toISOString(),
      bearbeitungsstatus: "IN_BEARBEITUNG",
      editModeEnabled: true,
      viewModeEnabled: false,
      createModeEnabled: true,
      titel: "",
      body: "",
      urheber: "",
    };

    setNoteField([
      ...noteField(),
      <NoteRow
        handleEdit={handleSave}
        handleDelete={deleteRow}
        note={newNote}
        opened="true"
        isEditMode="true"
      />,
    ]);
  };

  return (
    <>
      <tp-button
        appearance="secondary"
        onClick={addNewRow}
        style={{ "margin-left": "25px" }}
      >
        NEUE NOTIZ ANLEGEN
      </tp-button>

      <div class="new-elements">
        <For each={noteField()}>{(note) => <div>{note}</div>}</For>
      </div>

      {notes.loading ? (
        <tp-circular-progress />
      ) : (
        <For each={notes()}>
          {(note) => (
            <NoteRow
              note={note}
              handleEdit={handleEdit}
              handleDelete={handleDeleteDialog}
            />
          )}
        </For>
      )}

      <tp-dialog dialog-id="delete-note" id="delete-note-dialog">
        <div slot="title">Notiz löschen</div>

        <p />

        <div slot="action_buttons">
          <tp-button appearance="text-dark" onClick={closeDialog}>
            ZURÜCK
          </tp-button>

          <tp-button id="deleteButton" onClick={handleDelete}>
            LÖSCHEN
          </tp-button>
        </div>
      </tp-dialog>
    </>
  );
};

export default Notes;
