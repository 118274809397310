import * as cssText from "bundle-text:./tp-date-picker.scss";
import { createStyleSheet } from "../../utils/createStylesheets";
import todayIcon from "./icon/today-icon";

const styleSheets = createStyleSheet(cssText);

const template = document.createElement("template");
template.innerHTML = `<div class="tp-date-picker">
<div class="input-field">
  <div class="label"></div>
  <div class="subline"></div>
</div>
<div class="icon"></div>
<input type="date" />
</div>`;

class TpDatePicker extends HTMLElement {
  static get observedAttributes() {
    return ["value"];
  }

  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    this.shadowRoot.appendChild(template.content.cloneNode(true));
    this.shadowRoot.adoptedStyleSheets = [styleSheets];

    this.$input = this.shadowRoot.querySelector("input");
    this.$inputField = this.shadowRoot.querySelector(".input-field");
    this.$label = this.shadowRoot.querySelector(".label");

    this.$input.addEventListener("change", this.handleChangeEvent);
  }

  handleChangeEvent = (e) => {
    const value = e.currentTarget.value;

    if (value) {
      this.$label.nextElementSibling.textContent = new Date(
        value,
      ).toLocaleDateString("de-DE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });

      this.$inputField.classList.add("filled");
    } else {
      this.$label.nextElementSibling.textContent = "";
    }

    if (this.$label.classList.contains("small") === false) {
      this.$label.classList.add("small");
    }

    if (value === "") {
      this.$label.classList.remove("small");
      this.$inputField.classList.remove("filled");
    }
  };

  handleIconProperty() {
    const iconProperty = this.hasAttribute("has-icon");

    if (iconProperty) {
      const icon = `<img src="data:image/svg+xml;base64,${btoa(
        todayIcon,
      )}"  height="28" />`;
      this.shadowRoot.querySelector(".icon").innerHTML += icon;
    }
  }

  showPicker() {
    this.$input.showPicker();
  }

  handleValueProperty() {
    const valueProperty = this.getAttribute("value");
    const subline = this.shadowRoot.querySelector(".subline");

    if (!this.$input || !this.$label) return;

    if (
      valueProperty !== undefined &&
      valueProperty !== null &&
      valueProperty !== ""
    ) {
      subline.innerText = new Date(valueProperty).toLocaleDateString("de-DE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      this.$input.value = new Date(valueProperty).toISOString().split("T")[0];
      this.$label.classList.add("small");
      this.$inputField.classList.add("filled");
    } else {
      subline.innerText = "";
    }
  }

  initProperties() {
    const labelProperty = this.getAttribute("label");
    const idProperty = this.getAttribute("id");
    const disabledProperty = this.getAttribute("disabled") || "false";

    const datePickerContainer =
      this.shadowRoot.querySelector(".tp-date-picker");

    this.$label.innerText = labelProperty;
    datePickerContainer.setAttribute("id", idProperty);

    if (disabledProperty === "false") {
      datePickerContainer.addEventListener("click", this.showPicker.bind(this));
    } else {
      datePickerContainer.removeEventListener(
        "click",
        this.showPicker.bind(this),
      );
      this.$input.disabled = true;
    }
  }

  connectedCallback() {
    this.initProperties();
    this.handleValueProperty();
    this.handleIconProperty();
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (oldValue === newValue) return;
    if (name === "value") {
      this.handleValueProperty();
    }
  }
}

export default TpDatePicker;

customElements.define("tp-date-picker", TpDatePicker);
