import { render } from "solid-js/web";
import { Component, createEffect, createMemo, lazy, onMount } from "solid-js";
import { Route, Router } from "@solidjs/router";

import { STORE_TYPES, applicationStore, resetStores } from "./store";

import {
  HOMEPAGE_URL_REGISTER,
  NAVIGATION_INTENT,
  ROUTES,
} from "./constants/routes";

import "component-library/dist/index.js";
import Navigation from "./components/shared/navigation/navigation";

import Login from "./components/shared/login/login";
import { updateApplicationState } from "./components/shared/login/session";

const Fairs = lazy(() => import("./components/fairs/fairs.route"));

const Topics = lazy(() => import("./components/topics/topics.route"));
const SocialNews = lazy(
  () => import("./components/social-news/social-news.route"),
);
const Influencer = lazy(
  () => import("./components/influencer/influencer.route"),
);
const PasswordForgot = lazy(
  () => import("./components/forgot-password/forgot-password.route"),
);

const PasswordReset = lazy(
  () => import("./components/reset-password/reset-password.route"),
);

const ImportAdmin = lazy(() => import("./components/admin/import.route"));
const IndicesAdmin = lazy(() => import("./components/admin/indices.route"));
const UserManagementAdmin = lazy(
  () => import("./components/admin/user-management/user-management.route"),
);

const UserManagementAdminEdit = lazy(
  () => import("./components/admin/user-management/edit-user.route"),
);

const UserManagementAdminNew = lazy(
  () => import("./components/admin/user-management/new-user.route"),
);

const Activation = lazy(
  () => import("./components/admin/activation/activation.route"),
);

// myconvento
import Ssi from "./components/ssi/ssi.route";
import CollapsibleContent from "./components/collapsible-content/collapsible-content";

/* eslint-disable @typescript-eslint/no-namespace */
declare module "solid-js" {
  namespace JSX {
    interface IntrinsicElements {
      "tp-nav-item": unknown;
      "tp-nav": unknown;
      "tp-footer": unknown;
      "tp-header": unknown;
      "tp-result-toolbar": unknown;
      "tp-result-item": unknown;
      "tp-result-item-with-image": unknown;
      "tp-result-item-content-list": unknown;
      "tp-result-item-content-list-fact": unknown;
      "tp-result-item-content-list-fact-link": unknown;
      "tp-result-item-content-list-fact-media": unknown;
      "tp-button": unknown;
      "tp-radio": unknown;
      "tp-text-field": unknown;
      "tp-date-picker": unknown;
      "tp-select": unknown;
      "tp-select-item": unknown;
      "tp-multi-select": unknown;
      "tp-checkbox": unknown;
      "tp-dialog": unknown;
      "tp-collapsible-box": unknown;
      "tp-list-menu": unknown;
      "tp-circular-progress": unknown;
      "tp-help-tip": unknown;
      "tp-textarea": unknown;
      "tp-flags": unknown;
      "tp-switch": unknown;
      "tp-snackbar": unknown;
      "tp-toggle-container": unknown;
      "tp-radio-group": unknown;
      "tp-text-button": unknown;
    }
  }
}
const { applicationState, setApplicationState } = applicationStore;

const Main: Component = (props) => {
  onMount(async () => {
    // "invoke later", because it competes with the token based login via /ssi
    createEffect(() => {
      updateApplicationState();
    });
  });

  const variant = createMemo(() => {
    return applicationState.whitelabel ? "whitelabel" : "default";
  });

  return (
    <div id="page">
      <tp-header attr:variant={variant()}>
        <Login />
      </tp-header>
      <section>
        <Navigation />
        <CollapsibleContent />
      </section>

      {props.children}

      <tp-footer attr:variant={variant()} />

      <tp-dialog dialog-id="not-logged-in" id="not-logged-in-dialog">
        <div slot="title">Sie sind nicht eingeloggt</div>

        <p>
          In der Vollversion von TOPIC PRO erhalten Sie Zugriff auf die
          gewünschten Daten.
        </p>

        <div slot="action_buttons">
          <tp-button
            appearance="text-dark"
            onClick={(e) =>
              e.srcElement.parentNode.parentNode.dispatchEvent(
                new Event("not-logged-in:dialog:close"),
              )
            }
          >
            SCHLIESSEN
          </tp-button>

          <tp-button onClick={() => window.open(HOMEPAGE_URL_REGISTER)}>
            TOPIC PRO KOSTENLOS TESTEN
          </tp-button>
        </div>
      </tp-dialog>

      <tp-dialog dialog-id="not-subscribed" id="not-subscribed-dialog">
        <div slot="title">Diesen Bereich freischalten</div>

        <p>
          Dieser Bereich ist in Ihrem Abonnement nicht freigeschaltet. Wenn Sie
          Zugriff bzw. weitere Informationen hierzu erhalten möchten, klicken
          Sie bitte auf "Freischalten".
        </p>

        <div slot="action_buttons">
          <tp-button
            appearance="text-dark"
            onClick={(e) =>
              e.srcElement.parentNode.parentNode.dispatchEvent(
                new Event("not-subscribed:dialog:close"),
              )
            }
          >
            SCHLIESSEN
          </tp-button>

          <tp-button onClick={() => window.open(HOMEPAGE_URL_REGISTER)}>
            FREISCHALTEN
          </tp-button>
        </div>
      </tp-dialog>
    </div>
  );
};

/*
  Route load-function is called on various events. reset store only when navigation anchor is pushed
  and when there is no location.search content in the url.
*/
const resetStore = ({ location, intent }, type) => {
  if (intent !== NAVIGATION_INTENT.NAVIGATE) return;

  setApplicationState("showCollapsibleContent", false);

  if (location.search === "") {
    resetStores(type);
  }
};

render(
  () => (
    <Router root={Main}>
      <Route
        path={ROUTES.root}
        component={Topics}
        preload={(params) => resetStore(params, STORE_TYPES.TOPICS)}
      />
      <Route
        path={ROUTES.topics}
        component={Topics}
        preload={(params) => resetStore(params, STORE_TYPES.TOPICS)}
      />
      <Route
        path={ROUTES.socialNews}
        component={SocialNews}
        preload={(params) => resetStore(params, STORE_TYPES.SOCIAL)}
      />
      <Route
        path={ROUTES.fairs}
        component={Fairs}
        preload={(params) => resetStore(params, STORE_TYPES.FAIRS)}
      />
      <Route
        path={ROUTES.influencer}
        component={Influencer}
        preload={(params) => resetStore(params, STORE_TYPES.INFLUENCER)}
      />
      <Route path={ROUTES.passwordForget} component={PasswordForgot} />
      <Route path={ROUTES.passwordReset} component={PasswordReset} />
      <Route path={ROUTES.import} component={ImportAdmin} />
      <Route path={ROUTES.indices} component={IndicesAdmin} />
      <Route path={ROUTES.userManagement} component={UserManagementAdmin} />
      <Route
        path={ROUTES.userManagementEdit}
        component={UserManagementAdminEdit}
      />
      <Route path={ROUTES.activation} component={Activation} />
      <Route
        path={ROUTES.userManagementNew}
        component={UserManagementAdminNew}
      />
      <Route path={ROUTES.ssi} component={Ssi} />
    </Router>
  ),
  document.getElementById("page-container"),
);
