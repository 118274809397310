export const getTomorrowsDate = (): string => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow.toISOString().split("T")[0];
};

export const getToday = (): string => {
  const today = new Date();
  return today.toISOString().split("T")[0];
};

export const toIsoDate = (date) => {
  try {
    return new Date(date).toISOString().split("T")[0];
    // eslint-disable-next-line  @typescript-eslint/no-unused-vars
  } catch (_) {
    return null;
  }
};

export const formateDateString = (dateString: string): string => {
  if (!dateString || dateString.length === 0) return "";
  try {
    const parseToDate = new Date(dateString);
    return parseToDate.toLocaleDateString("de-DE", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    // eslint-disable-next-line  @typescript-eslint/no-unused-vars
  } catch (_) {
    // console.log("Dateparsing Error", error);
    return "";
  }
};
