import "@material/web/menu/menu";
import "@material/web/menu/menu-item";
import "@material/web/divider/divider";

import * as css from "bundle-text:./tp-list-menu.scss";

import { createStyleSheet } from "../../utils/createStylesheets";
const styleSheets = createStyleSheet(css);

const template = document.createElement("template");
template.innerHTML = `<div class="tp-list-menu"><md-menu positioning="popover" default-focus="NONE"></md-menu></div>`;

class TpListMenu extends HTMLElement {
  static get observedAttributes() {
    return ["open", "items"];
  }

  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    this.shadowRoot.appendChild(template.content.cloneNode(true));
    this.shadowRoot.adoptedStyleSheets = [styleSheets];
  }

  connectedCallback() {
    this.$element = this.shadowRoot.querySelector("md-menu");
    for (const attribute of this.attributes) {
      if (attribute.name === "anchor") {
        this.shadowRoot
          .querySelector("div")
          .setAttribute("id", attribute.value);
      }
      this.$element.setAttribute(attribute.name, attribute.value);
    }

    this.$element.addEventListener("close-menu", this.handleOnChange);
    this.addEventListener("menu:close", this.handleClose);
    this.addEventListener("menu:open", this.handleOpen);
  }

  set items(value) {
    this._items = value;
    this.removeOldNodes();
    this.render();
  }

  get items() {
    return this._items;
  }

  removeOldNodes() {
    this.shadowRoot.querySelector("md-menu").innerHTML = "";
  }

  render() {
    this.items.forEach((item, index) => {
      const li = document.createElement("md-menu-item");
      li.setAttribute("id", index);
      li.innerHTML = `<div slot="headline">${item}</div>`;

      this.shadowRoot.querySelector("md-menu").appendChild(li);
    });
  }

  handleOnChange(event) {
    const onChangeEvent = new CustomEvent("change", {
      composed: true,

      detail: {
        index: event.detail.initiator.id,
        value: event.target.innerText,
      },
    });

    this.dispatchEvent(onChangeEvent);
  }

  handleOpen() {
    // hack because if you click multiple times on the
    // opening button, the menulist will close and never open
    // until you click elsewhere
    this.handleClose();
    setTimeout(() => {
      this.$element.show();
    }, 200);
  }

  handleClose() {
    this.$element.close();
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (oldValue === newValue) return;
    if (name === "open") {
      if (this.hasAttribute("open")) {
        this.$element.show();
      } else {
        this.$element.close();
      }
    }
  }

  disconnectedCallback() {
    this.$element.removeEventListener("close-menu", this.fireEvent);
    this.removeEventListener("menu:close", this.handleClose);
    this.removeEventListener("menu:open", this.handleOpen);
  }
}

export default TpListMenu;

customElements.define("tp-list-menu", TpListMenu);
