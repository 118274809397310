import * as cssText from "bundle-text:./tp-multi-select.scss";
import { createStyleSheet } from "../../utils/createStylesheets";

const styleSheets = createStyleSheet(cssText);

const componentClass = "tp-multi-select";
const template = document.createElement("template");
template.innerHTML = `<div class="${componentClass}" role="combobox">
<div class="${componentClass}-field" tabindex="0"></div>
<div class="${componentClass}-popup">
    <ul class="${componentClass}-list" role="listbox" aria-multiselectable="true">
       <slot/>
    </ul>
</div>
</div>`;

class TpMultiSelect extends HTMLElement {
  static get observedAttributes() {
    return ["selected-items"];
  }

  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    this.shadowRoot.appendChild(template.content.cloneNode(true));
    this.shadowRoot.adoptedStyleSheets = [styleSheets];

    this._items = [];

    this._control = this.shadowRoot.querySelector(`.${componentClass}`);
    this._field = this.shadowRoot.querySelector(`.${componentClass}-field`);
    this._popup = this.shadowRoot.querySelector(`.${componentClass}-popup`);
    this._list = this.shadowRoot.querySelector(`.${componentClass}-list`);

    this.render();
  }

  connectedCallback() {
    this._placeholder = this.getAttribute("placeholder") || "Select";

    this.setSelectedItems();

    document.addEventListener("click", (event) => {
      if (!event.composedPath().includes(this)) {
        this.close();
      }
    });
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (oldValue === newValue) return;
    if (name === "selected-items") {
      this.setSelectedItems();
    }
  }

  initHandlers = () => {
    this._field.addEventListener("click", this.fieldClickHandler);
    this._control.addEventListener("keydown", this.keyDownHandler);
    this._list.addEventListener("click", this.listClickHandler);

    // check if fields are preselected via document.queryselector.addAttribute
    this.shadowRoot.querySelector("slot").addEventListener("slotchange", () => {
      this.refreshField();
      this.refreshItems();
    });
  };

  render = () => {
    this.initHandlers();
    this.refreshField();
    this.refreshItems();
  };

  fieldClickHandler = () => {
    this._isOpened ? this.close() : this.open();
  };

  handleEnterKey = () => {
    if (this._isOpened) {
      const focusedItem = this.itemElements()[this._focusedItemIndex];
      this.selectItem(focusedItem);
    }
  };

  handleArrowDownKey = () => {
    this._focusedItemIndex =
      this._focusedItemIndex < this.itemElements().length - 1
        ? this._focusedItemIndex + 1
        : 0;

    this.refreshFocusedItem();
  };

  handleArrowUpKey = () => {
    this._focusedItemIndex =
      this._focusedItemIndex > 0
        ? this._focusedItemIndex - 1
        : this.itemElements().length - 1;

    this.refreshFocusedItem();
  };

  handleAltArrowDownKey = () => {
    this.open();
  };

  handleAltArrowUpKey = () => {
    this.close();
  };

  refreshFocusedItem = () => {
    this.itemElements()[this._focusedItemIndex]?.focus();
  };

  handleBackspaceKey = () => {
    const selectedItemElements = this.querySelectorAll("li[selected]");

    if (selectedItemElements.length) {
      this.unselectItem(selectedItemElements[selectedItemElements.length - 1]);
    }
  };

  keyDownHandler = (event) => {
    switch (event.which) {
      case 8:
        this.handleBackspaceKey();
        break;
      case 13:
        this.handleEnterKey();
        break;
      case 27:
        this.handleEscapeKey();
        break;
      case 38:
        event.altKey ? this.handleAltArrowUpKey() : this.handleArrowUpKey();
        break;
      case 40:
        event.altKey ? this.handleAltArrowDownKey() : this.handleArrowDownKey();
        break;
      default:
        return;
    }
    event.preventDefault();
  };

  listClickHandler = (event) => {
    let item = event.target;
    while (item && item.tagName !== "LI") {
      item = item.parentNode;
    }

    this.selectItem(item);
  };

  selectItem = (item) => {
    if (item && !item.hasAttribute("selected")) {
      item.setAttribute("selected", "selected");
      item.setAttribute("aria-selected", true);
      this.refreshField();
      this.fireChangeEvent();
    }

    this.close();
  };

  handleEscapeKey = () => {
    this.close();
  };

  fireChangeEvent = () => {
    const event = new CustomEvent("change", { detail: this._items });
    this.dispatchEvent(event);
  };

  togglePopup = (show) => {
    this._isOpened = show;
    this._popup.style.display = show ? "block" : "none";
    this._control.setAttribute("aria-expanded", show);
  };

  toggleArrow = (show) => {
    const arrowUp = this.shadowRoot.querySelector(".arrow.up");
    const arrowDown = this.shadowRoot.querySelector(".arrow.down");

    if (show === true) {
      arrowDown.classList.add("active");
      arrowUp.classList.remove("active");
    } else {
      arrowDown.classList.remove("active");
      arrowUp.classList.add("active");
    }
  };

  refreshField = () => {
    this._field.innerHTML = "";
    const tmpItems = [];

    const selectedItems = this.querySelectorAll("li[selected]");

    if (!selectedItems.length) {
      const placeholder = this.createPlaceholder();

      const caret = this.createSVGCaret();
      placeholder.appendChild(caret);
      this._field.appendChild(placeholder);
      this._items = [];
    } else {
      for (let i = 0; i < selectedItems.length; i++) {
        this._field.appendChild(this.createTag(selectedItems[i]));
        tmpItems.push(selectedItems[i].getAttribute("value"));
      }

      this._items = tmpItems;

      this._field.appendChild(this.createSVGCaret());
    }
  };

  refreshItems = () => {
    const itemElements = this.itemElements();

    for (let i = 0; i < itemElements.length; i++) {
      const itemElement = itemElements[i];
      itemElement.setAttribute("role", "option");
      itemElement.setAttribute(
        "aria-selected",
        itemElement.hasAttribute("selected"),
      );
      itemElement.setAttribute("tabindex", -1);
    }

    this._focusedItemIndex = 0;
  };

  itemElements = () => {
    return this.querySelectorAll("li");
  };

  createPlaceholder = () => {
    const placeholder = document.createElement("div");
    placeholder.className = `${componentClass}-field-placeholder`;
    placeholder.textContent = this._placeholder;
    return placeholder;
  };
  createSVGCaret = () => {
    const caret = document.createElement("div");
    caret.className = "caret-container";
    caret.innerHTML = `
    <svg
        class="caret"
        viewBox="7 10 10 5" focusable="false">
      <polygon
          class="arrow up active"
          stroke="none"
          fill-rule="evenodd"
          points="7 10 12 15 17 10">
      </polygon>
      <polygon
          class="arrow down"
          stroke="none"
          fill-rule="evenodd"
          points="7 15 12 10 17 15">
      </polygon>
    </svg>`;
    return caret;
  };

  createTag = (item) => {
    const tag = document.createElement("div");
    tag.className = `${componentClass}-tag`;
    const content = document.createElement("div");
    content.className = `${componentClass}-tag-text`;
    content.textContent = item.textContent;

    const shortLabel = item.getAttribute("short");
    if (shortLabel) {
      content.textContent = shortLabel;
    }

    const removeButton = document.createElement("div");
    removeButton.className = `${componentClass}-tag-remove-button`;
    removeButton.addEventListener(
      "click",
      this.removeTag.bind(this, tag, item),
    );

    tag.appendChild(content);
    tag.appendChild(removeButton);

    return tag;
  };

  unselectItem = (item) => {
    item.removeAttribute("selected");
    item.setAttribute("aria-selected", false);
    this.refreshField();
    this.fireChangeEvent();
  };

  close = () => {
    this.togglePopup(false);
    this.toggleArrow(false);
  };

  open = () => {
    this.togglePopup(true);

    this.refreshFocusedItem();

    this.toggleArrow(true);
  };

  removeTag = (tag, item, event) => {
    this.unselectItem(item);
    event.stopPropagation();
  };

  selectedItems = () => {
    const result = [];
    const selectedItems = this.querySelectorAll("li[selected]");

    for (let i = 0; i < selectedItems.length; i++) {
      const selectedItem = selectedItems[i];

      const item = { value: "", text: "" };
      item.value = selectedItem.hasAttribute("value")
        ? selectedItem.getAttribute("value")
        : selectedItem.textContent;

      item.text = selectedItem.textContent;
      result.push(item);
    }

    return result;
  };

  setSelectedItems = () => {
    const selectedItemsAttribute = this.getAttribute("selected-items");
    const listItems = this.querySelectorAll("li");
    try {
      if (
        selectedItemsAttribute === null ||
        selectedItemsAttribute == undefined
      )
        return;

      const parsedItems = selectedItemsAttribute.split(",");
      if (parsedItems && parsedItems.length > 0) {
        listItems.forEach((item) => {
          if (parsedItems.includes(item.getAttribute("value"))) {
            this.selectItem(item);
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
}

export default TpMultiSelect;

customElements.define("tp-multi-select", TpMultiSelect);
