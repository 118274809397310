import { Component } from "solid-js";

import "./help.scss";

const Help: Component = () => {
  return (
    <>
      <div class="help-content">
        <div>
          <div class="headline">
            So funktioniert die Themendatenbank TOPIC PRO
          </div>
          Um eine Suche zu starten, geben Sie bitte Ihre Suchbegriffe und den
          gewünschten Erscheinungszeitraum ein und klicken Sie auf „Suchen“. Das
          entsprechende Suchergebnis wird Ihnen daraufhin sortiert nach
          Erscheinungstermin angezeigt.
        </div>

        <div class="tip-row">
          <tp-help-tip
            title="Tipp 1: viele relevante Themen finden"
            do="regeltechnik steuerungstechnik messtechnik emv"
            dont="regeltechnik"
          >
            Geben Sie mehrere Suchbegriffe ein, um größere Suchergebnisse zu
            erzielen. In TOPIC PRO erfolgt die Suche volltextbasiert. Es werden
            alle Treffer angezeigt, die einen der Suchbegriffe enthalten.
            Trennen Sie Begriffe bitte durch Leerzeichen.
          </tp-help-tip>

          <tp-help-tip
            title="Tipp 2: Sternchen-Taste"
            do="*immobilie"
            dont="immobilie"
          >
            Setzen Sie die Sternchen-Taste als Platzhalter für
            Buchstabenkombinationen vor Ihre Suchbegriffe. In diesem Beispiel
            bekommen Sie dann auch Themen wie „Wohnimmobilien“,
            „Gewerbeimmobilien“ usw. angezeigt.
          </tp-help-tip>
        </div>

        <div class="tip-row">
          <tp-help-tip
            title="Tipp 3: UND-Suche"
            do='"industrie 4.0" "internet of things" iot'
            dont="industrie 4.0 internet of things iot"
          >
            Setzen Sie eindeutig zusammenhängende Suchbegriffe bitte in
            Anführungszeichen, um aus der ODER-Suche eine UND-Suche zu machen.
            Sie bekommen daraufhin im Suchergebnis Themen angezeigt, die die
            entsprechenden Begriffe enthalten.
          </tp-help-tip>

          <tp-help-tip
            title="Tipp 4: Suche nach dem Wortstamm"
            do="*heiz"
            dont="heizungen"
          >
            Bei manchen Suchabfragen empfiehlt sich eine Suche nach dem
            Wortstamm, da Buchstaben hinter den Suchbegriffen automatisch
            ergänzt werden. Hier werden auch Treffer wie Heiztechnik, Heizung,
            heizen usw. angezeigt:
          </tp-help-tip>
        </div>

        <p>
          Eine Suchabfrage nach Mobilitätsthemen könnte wie folgt aussehen:
          *mobilität *mobility "autonomes fahren" "e-auto" fahr pkw usw.
        </p>
        <p>
          Weitere Hinweise für beste Suchergebnisse finden Sie auf
          https://hilfe.mountbarley.de
        </p>
        <p>
          Viel Erfolg mit TOPIC PRO wünscht Ihnen das Team von Mount Barley
          Publishing!
        </p>
      </div>
    </>
  );
};

export default Help;
