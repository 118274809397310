import * as cssText from "bundle-text:./tp-nav.scss";
import { createStyleSheet } from "../../../utils/createStylesheets";

const styleSheets = createStyleSheet(cssText);

const template = document.createElement("template");
template.innerHTML = `<ul class="nav"><slot></slot> <slot name="right"/></ul>`;

class TPNav extends HTMLElement {
  constructor() {
    super();

    this.attachShadow({ mode: "open" });
    this.shadowRoot.appendChild(template.content.cloneNode(true));
    this.shadowRoot.adoptedStyleSheets = [styleSheets];
  }
}

customElements.define("tp-nav", TPNav);
