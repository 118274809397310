export const deFlag = `
  <svg xmlns="http://www.w3.org/2000/svg" height="512" width="512" version="1">
    <g fill-rule="evenodd" stroke-width="1pt">
      <path fill="#fc0" d="M0 341.338h512.005v170.67H0z" />
      <path d="M0 0h512.005v170.67H0z" />
      <path fill="#f00" d="M0 170.67h512.005v170.668H0z" />
    </g>
  </svg>`;

export const atFlag = `<svg xmlns="http://www.w3.org/2000/svg" height="512" width="512" version="1">
<g fill-rule="evenodd">
  <path fill="#fff" d="M512 512H0V0h512z"/>
  <path fill="#df0000" d="M512 512H0V341.33h512zM512 170.8H0V.13h512z"/>
</g>
</svg>`;

export const chFlag = `<svg xmlns="http://www.w3.org/2000/svg" height="512" width="512" version="1">
<g fill-rule="evenodd" stroke-width="1pt">
  <path fill="#d52b1e" d="M0 0h511.984v511.983H0z"/>
  <g fill="#fff">
    <path d="M95.994 207.993h319.99v95.994H95.993z"/>
    <path d="M207.99 95.995h95.995v319.99H207.99z"/>
  </g>
</g>
</svg>`;
