import "@material/web/dialog/dialog";

import * as css from "bundle-text:./tp-dialog.scss";
import { createStyleSheet } from "../../utils/createStylesheets";

import cancelIcon from "./icon/cancel_black";

const styleSheets = createStyleSheet(css);

const template = document.createElement("template");
template.innerHTML = `<md-dialog class="tp-dialog">
<div slot="headline"><slot name="title"></slot></div>
<div slot="content"><slot></slot></div>
<div slot="actions">
  <slot name="action_buttons"></div>
</div>
</md-dialog>`;

class TpDialog extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    this.shadowRoot.appendChild(template.content.cloneNode(true));
    this.shadowRoot.adoptedStyleSheets = [styleSheets];
  }

  closeDialog(e) {
    e.target.closest("md-dialog")?.close();
  }

  connectedCallback() {
    const idProperty = this.getAttribute("dialog-id") || "default";

    this.$dialog = this.shadowRoot.querySelector("md-dialog");
    this.addEventListener(`${idProperty}:dialog:open`, () => {
      this.$dialog.show();
    });

    this.addEventListener(`${idProperty}:dialog:close`, () => {
      this.$dialog.close();
    });

    if (this.hasAttribute("cancel-icon")) {
      const iconImage = document.createElement("img");
      iconImage.classList.add("close-icon");
      iconImage.src = `data:image/svg+xml;base64,${btoa(cancelIcon)}`;
      iconImage.setAttribute("height", "28");
      iconImage.addEventListener("click", this.closeDialog);

      this.shadowRoot.querySelector("[slot='headline']").append(iconImage);
    }
  }

  disconnectedCallback() {
    this.removeEventListener("dialog:open", null);
    this.shadowRoot
      .querySelector(".close-icon")
      ?.removeEventListener(this.closeDialog);
  }
}

export default TpDialog;

customElements.define("tp-dialog", TpDialog);
