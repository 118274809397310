import * as css from "bundle-text:./tp-result-toolbar.scss";
import { createStyleSheet } from "../../utils/createStylesheets";

const styleSheets = createStyleSheet(css);

const template = document.createElement("template");
template.innerHTML = `<div class="toolbar">
    <div class="mdc-layout-grid padding">
        <div class="mdc-layout-grid__inner flex-space-between">
            <div class="result mdc-layout-grid__cell mdc-layout-grid__cell--span-4"></div>
            <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-8 flex">
              <slot/>
            </div>
        </div>
    </div>
</div>`;

class TpResultToolbar extends HTMLElement {
  static get observedAttributes() {
    return ["result"];
  }

  constructor() {
    super();

    this.attachShadow({ mode: "open" });
    this.shadowRoot.appendChild(template.content.cloneNode(true));
    this.shadowRoot.adoptedStyleSheets = [styleSheets];

    this.updateResult();
  }

  attributeChangedCallback() {
    this.updateResult();
  }

  updateResult() {
    const result = this.getAttribute("result");
    this.shadowRoot.querySelector(".result").textContent = result;
  }
}

customElements.define("tp-result-toolbar", TpResultToolbar);
