import * as cssText from "bundle-text:./tp-text-button.scss";
import { createStyleSheet } from "../../utils/createStylesheets";

const styleSheets = createStyleSheet(cssText);

const template = document.createElement("template");
template.innerHTML = `<button class="tp-text-button"><slot /></button>`;

class TpTextButton extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    this.shadowRoot.appendChild(template.content.cloneNode(true));
    this.shadowRoot.adoptedStyleSheets = [styleSheets];
  }
}

export default TpTextButton;

customElements.define("tp-text-button", TpTextButton);
